import type { FC } from 'react';
import { useEffect } from 'react';

import { Box, Grid, Typography } from '@material-ui/core';
import Fade from '@material-ui/core/Fade';
import NProgress from 'nprogress';

import Logo from 'src/components/Logo';
import { getMessage } from 'src/utils/loadingMessages';

const LoadingScreen: FC = () => {
  useEffect(() => {
    NProgress.start();

    return (): void => {
      NProgress.done();
    };
  }, []);

  return (
    <Fade in>
      <Box
        sx={{
          backgroundColor: 'background.paper',
          minHeight: '100%',
          position: 'relative',
          overflow: 'hidden',
        }}
      >
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          height="100vh"
          position="absolute"
          direction="column"
        >
          <Grid item>
            <Box>
              <Logo
                sx={{
                  height: 120,
                  width: 120,
                }}
              />
            </Box>
          </Grid>
          <Grid item>
            <Typography>
              {getMessage()}
            </Typography>
          </Grid>
        </Grid>
      </Box>
    </Fade>
  );
};

export default LoadingScreen;
