import { createContext, FC, ReactNode, useEffect, useMemo, useState } from 'react';

import { Auth0Client } from '@auth0/auth0-spa-js';
import jwt_decode from 'jwt-decode';
import PropTypes from 'prop-types';
import { useCookies } from 'react-cookie';

import { Api } from 'src/api/zrm';
import { AuthUser } from 'src/types/auth';
import { User } from 'src/types/user';
import { isCustomerPageContext } from 'src/utils/isCustomerPageContext';
import logger from 'src/utils/logger';

import { auth0Config } from '../config';

export enum AuthenticationPlatform {
  Auth0 = 'Auth0',
  JWT = 'JWT',
  BankIdNo = 'BankIdNo',
}

type CompleteState = 'none' | 'checkSession' | 'complete' | 'timeout';

interface AuthState {
  state: CompleteState;
  isAuthenticated: boolean,
  token?: string;
  user?: AuthUser;
  platform: AuthenticationPlatform;
}

const initialState: AuthState = {
  state: 'none',
  isAuthenticated: false,
  token: null,
  user: null,
  platform: localStorage.getItem('login_provider') as string as AuthenticationPlatform ?? null,
};

export interface AuthContextValue {
  isAuthenticated: boolean;
  authUser: AuthUser;
  completeState: CompleteState;
  platform: AuthenticationPlatform;
  loginWithPopup?: (options?: any) => Promise<void>;
  logout: () => void;
  getAccessToken: () => Promise<string>;
  getUser: () => Promise<User>;
  login?: (username: string, password?: string) => Promise<void>;
  togglePlatform?: () => void;
  verify2FACode?: (otp: string) => Promise<void>;
  waitingForOTP?: boolean;
  changePlatform?: (platform: AuthenticationPlatform) => void;
  setImpersonationToken: (token: string) => void;
}

interface AuthProviderProps {
  children: ReactNode;
}

const AuthContext = createContext<AuthContextValue>({
  isAuthenticated: false,
  authUser: null,
  completeState: 'none',
  platform: AuthenticationPlatform.Auth0,
  loginWithPopup: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  getAccessToken: () => Promise.resolve(''),
  getUser: () => Promise.resolve(null),
  togglePlatform: () => {},
  setImpersonationToken: () => {},
  // login: () => Promise.resolve(),
  // verify2FACode: async () => Promise.resolve(''),
});

const api = new Api({
  baseUrl: process.env.REACT_APP_ZRM_URL,
});

const defaultAuthPlatform = () => {
  if (isCustomerPageContext()) return AuthenticationPlatform.BankIdNo;

  return localStorage.getItem('login_provider') as string as AuthenticationPlatform ?? AuthenticationPlatform.Auth0;
};

export const AuthProvider: FC<AuthProviderProps> = (props) => {
  const removeCookie = useCookies(['websocketToken'])[2]; // Lint warnings fix
  const [authState, setAuthState] = useState<AuthState>(initialState); // Info about logged user

  const [platform, setPlatform] = useState<AuthenticationPlatform>(defaultAuthPlatform());
  const { children } = props;

  const changePlatform = useMemo(() => (newPlatform: AuthenticationPlatform) => {
    setPlatform(newPlatform);
    setAuthState((p) => ({ ...p, state: 'complete' }));
  }, []);

  const auth0Client = useMemo(() => platform === AuthenticationPlatform.Auth0 && new Auth0Client({
    redirect_uri: window.location.origin,
    ...auth0Config,
  }), [auth0Config, window.location.origin]);

  const checkSession = useMemo(() => async (): Promise<void> => {
    await auth0Client.checkSession();

    const isAuthenticated = await auth0Client.isAuthenticated();

    const [user, token] = isAuthenticated ? await Promise.all([
      auth0Client.getUser(),
      auth0Client.getTokenSilently(),
    ]) : [null, null];

    setAuthState((p) => {
      const newState = {
        platform: AuthenticationPlatform.Auth0,
        isAuthenticated,
        user,
        token,
      };

      return { ...p,
        ...newState,
        user: newState.user && {
          ...(newState.user['https://zensum.se/app_metadata'] || newState.user),
          user_id: newState.user.sub,
          avatar: newState.user.picture,
        },
        state: 'complete' };
    });
  }, [auth0Client]);

  const logout = useMemo(() => async (): Promise<void> => {
    await auth0Client.logout();

    setAuthState((p) => ({ ...p,
      ...initialState,
      state: 'complete' }
    ));
  }, [auth0Client]);

  const logoutJWT = useMemo(() => async (): Promise<void> => {
    localStorage.removeItem('bank_token');
    setAuthState((p) => (
      { ...p,
        ...initialState,
        state: 'complete' }
    ));
  }, []);

  const loginJWT = useMemo(() => async (token: string): Promise<void> => {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    const { first_name, last_name, user_id, username, banks, exp } = jwt_decode(token) as any;

    if (new Date() > new Date(exp * 1000)) logoutJWT();
    else setAuthState((p) => {
      const newState = {
        isAuthenticated: true,
        platform: AuthenticationPlatform.JWT,
        user: {
          user_id,
          first_name,
          last_name,
          username,
          banks,
          name: `${first_name} ${last_name}`,
        },
        token,
      };

      return { ...p,
        ...newState,
        user: newState.user && {
          ...(newState.user['https://zensum.se/app_metadata'] || newState.user),
          id: newState.user.user_id,
        },
        state: 'complete' };
    });
  }, [jwt_decode, logoutJWT]);

  const logoutBankIdNo = useMemo(() => async (timeout = false): Promise<void> => {
    setAuthState((p) => (
      { ...p,
        ...initialState,
        state: timeout ? 'timeout' : 'none' }
    ));
    localStorage.removeItem('bankIdNoToken');
  }, []);

  const [bankIdNoLoginState, setBankIdNoLoginState] = useState<{ token?: string, decoded_token?: { [k: string]: any } }>({ token: localStorage.getItem('bankIdNoToken') });

  const loginBankIdNo = useMemo(() => async (token: string): Promise<void> => {
    /* eslint-disable-next-line @typescript-eslint/naming-convention */
    const { first_name, last_name, sub: pni, customer_id, exp, ticket_id, impersonate, impersonator_name, impersonator_id } = jwt_decode(token) as any;
    const expirationInMs = exp * 1000;

    if (new Date() > new Date(expirationInMs)) logoutBankIdNo(true);
    else {
      setAuthState((p) => {
        const newState = {
          isAuthenticated: true,
          platform: AuthenticationPlatform.BankIdNo,
          user: {
            first_name,
            last_name,
            pni,
            name: `${first_name} ${last_name}`,
            user_id: customer_id,
            ticket_id,
            exp: expirationInMs,
            impersonate,
            impersonator_name,
            impersonator_id,
          },
          token,
        };

        return { ...p,
          ...newState,
          user: newState.user,
          state: 'complete',
        };
      });
      setBankIdNoLoginState((p) => ({ ...p, token, decoded_token: jwt_decode(token) }));
      localStorage.setItem('bankIdNoToken', token);
    }
  }, [jwt_decode, logoutBankIdNo]);

  useEffect(() => {
    localStorage.setItem('login_provider', platform);

    if (platform === AuthenticationPlatform.Auth0 && auth0Client) checkSession();
    else if (platform === AuthenticationPlatform.JWT) {
      const token = localStorage.getItem('bank_token');

      if (token) loginJWT(token);
    } else if (platform === AuthenticationPlatform.BankIdNo) {
      const token = localStorage.getItem('bankIdNoToken');

      if (token) loginBankIdNo(token);
    }
  }, [auth0Client, platform]);

  const auth0Props: AuthContextValue = useMemo(() => auth0Client && ({
    isAuthenticated: authState.isAuthenticated,
    authUser: authState.user,
    completeState: authState.state,
    platform,
    loginWithPopup: async (options): Promise<void> => {
      await auth0Client.loginWithPopup(options);
      await checkSession();
    },
    logout: async (): Promise<void> => {
      removeCookie('websocketToken', { path: '/' });

      return logout();
    },
    getUser: async (): Promise<User> => {
      try {
        await auth0Client.getTokenSilently();
        const user = await auth0Client.getUser();

        return user as User;
      } catch (e) {
        return null;
      }
    },
    getAccessToken: async (): Promise<string | undefined> => {
      try {
        return await auth0Client.getTokenSilently();
      } catch (e) {
        // Do nothing
      }

      return undefined;
    },
    togglePlatform: () => setPlatform((p) => p === AuthenticationPlatform.JWT ? AuthenticationPlatform.Auth0 : AuthenticationPlatform.JWT),
    changePlatform,
    setImpersonationToken: loginBankIdNo,
  }), [platform, auth0Client, authState, changePlatform, loginBankIdNo]);

  const [jwtState, setJwtState] = useState<{ session_id?: string, token?: string, decoded_token?: { [k: string]: any } }>({ token: localStorage.getItem('bank_token') });

  const jwtLoginProps: AuthContextValue = useMemo(() => platform === AuthenticationPlatform.JWT && ({
    authUser: authState.user,
    completeState: authState.state,
    platform,
    login: async (username: string, password: string) => {
      const { data: loginResp } = await api.bank.loginBankLoginPost({ username, password });
      setJwtState({ session_id: loginResp.session_id });
    },
    togglePlatform: () => setPlatform((p) => p === AuthenticationPlatform.JWT ? AuthenticationPlatform.Auth0 : AuthenticationPlatform.JWT),
    verify2FACode: async (otp: string) => {
      const { data: { token } } = await api.bank.validateOtpCodeBankLoginValidatePost({ otp, session_id: jwtState.session_id });
      localStorage.setItem('bank_token', token); // store it securely
      setJwtState((p) => ({ ...p, token, decoded_token: jwt_decode(token) }));
      loginJWT(token);
    },
    isAuthenticated: !!jwtState.token,
    logout: () => {
      removeCookie('websocketToken', { path: '/' });
      setJwtState({});
      logoutJWT();
    },
    getUser: async () => (jwtState.decoded_token as any),
    getAccessToken: () => Promise.resolve(jwtState.token), // check expiration
    waitingForOTP: !!jwtState.session_id,
    changePlatform,
    setImpersonationToken: loginBankIdNo,
  }), [platform, jwtState, setJwtState, authState, changePlatform, loginBankIdNo]);

  const checkLoginStatus = useMemo(() => (token: string) => {
    if (platform !== AuthenticationPlatform.JWT) return;
    if (!jwtState.token) setJwtState((p) => ({ ...p, token }));

    loginJWT(token);
  }, [jwtState, platform]);

  const bankIdNoLoginProps: AuthContextValue = useMemo(() => platform === AuthenticationPlatform.BankIdNo && ({
    authUser: authState.user,
    completeState: authState.state,
    platform,
    login: async (token: string) => {
      loginBankIdNo(token);
    },
    isAuthenticated: !!bankIdNoLoginState.token,
    logout: () => {
      setBankIdNoLoginState({});
      logoutBankIdNo();
    },
    getUser: async () => (bankIdNoLoginState.decoded_token as any),
    getAccessToken: () => Promise.resolve(bankIdNoLoginState.token), // check expiration
    changePlatform,
    setImpersonationToken: loginBankIdNo,
  }), [platform, bankIdNoLoginState, authState, changePlatform, loginBankIdNo, logoutBankIdNo]);

  useEffect(() => {
    const token = localStorage.getItem('bank_token');

    if (token) checkLoginStatus(token);
  }, []);

  useEffect(() => {
    if (!jwtState.token) return () => {};

    const { exp } = jwt_decode(jwtState.token) as any;

    if (new Date() > new Date(exp * 1000)) {
      jwtLoginProps?.logout();

      return () => {};
    }

    const timeLeft = new Date(exp * 1000).getTime() - new Date().getTime();
    const t = setTimeout(() => {
      jwtLoginProps?.logout();
    }, timeLeft - 2 * 60 * 1000);

    return () => { clearTimeout(t); };
  }, [jwtState.token]);

  // TODO test token expiration
  useEffect(() => {
    if (!bankIdNoLoginState.token) return () => {};

    const { exp } = jwt_decode(bankIdNoLoginState.token) as any;

    if (new Date() > new Date(exp * 1000)) {
      bankIdNoLoginProps?.logout();

      return () => {};
    }

    const timeLeft = new Date(exp * 1000).getTime() - new Date().getTime();
    const t = setTimeout(() => {
      bankIdNoLoginProps?.logout();
    }, timeLeft - 2 * 60 * 1000);

    return () => { clearTimeout(t); };
  }, [bankIdNoLoginState.token]);

  const value = useMemo((): AuthContextValue => {
    switch (platform) {
      case AuthenticationPlatform.Auth0:
        return auth0Props;
      case AuthenticationPlatform.JWT:
        return jwtLoginProps;
      default:
        return bankIdNoLoginProps;
    }
  }, [platform, jwtLoginProps, auth0Props, bankIdNoLoginProps]);

  // Update `userId` in logger every time auth value changes
  useEffect(() => {
    if (!value) return;

    (async () => {
      const user = await value.getUser();
      const userId = user?.sub || user?.user_id;

      logger.setUserId(userId);
    })();
  }, [value]);

  useEffect(() => {
    if (platform !== AuthenticationPlatform.BankIdNo) {
      const hotjar = window.document.getElementById('hotjar');

      if (hotjar) hotjar.remove();
    } else if (window && document) {
      const script = document.createElement('script');
      script.async = true;
      script.id = 'hotjar';
      script.text = `/* <!-- Hotjar Tracking Code for Zensum NO --> */
  (function(h,o,t,j,a,r){
  h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
  h._hjSettings={hjid:2455361,hjsv:6};
  a=o.getElementsByTagName('head')[0];
  r=o.createElement('script');r.async=1;
  r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
  a.appendChild(r);
})(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  `;
      const body = document.getElementsByTagName('body')[0];
      body.appendChild(script);
    }
  }, [platform]);

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default AuthContext;
