import { createContext, useEffect, useMemo, useRef, useState } from 'react';

import { EventSourcePolyfill } from 'event-source-polyfill';
import isEqual from 'fast-deep-equal';
import { v4 as uuid } from 'uuid';

import { Conversation } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useAuth from 'src/hooks/useAuth';
import useSettings from 'src/hooks/useSettings';
import logger from 'src/utils/logger';

import { useDialmakerDesktopUtils } from './dialmakerDesktop/DialmakerDesktopUtilsContext';

export interface MessagingContextValue {
  conversations: Array<Conversation>,
  addConversation: (customerId?: string, applicationIdOrLeadId?: string, phoneNumber?: string, pni?: string, open?: boolean) => void,
  removeConversation: (customerId: string) => void,
  maximizeConversation: (customer_id: string) => void,
  minimizeConversation: (customer_id: string) => void,
}

const MessagingContext = createContext<MessagingContextValue>(null);

enum LastConversationChangeSource {
  BACKEND,
  FRONTEND,
}

const STREAM_URL = `${process.env.REACT_APP_ZRM_STREAM_URL || process.env.REACT_APP_ZRM_URL}/message/conversations/stream`;

export const MessagingProvider = ({ children }) => {
  const { api } = useApi();
  const { isAuthenticated, platform, getAccessToken } = useAuth();
  const { settings } = useSettings();
  const { isDesktopDialmakerApp } = useDialmakerDesktopUtils();

  const [conversations, setConversations] = useState<Array<Conversation>>([]);
  const lastConversationChangeSource = useRef<LastConversationChangeSource>(LastConversationChangeSource.BACKEND);

  const value = useMemo(() => ({
    conversations,
    // TODO save conversation after open or close
    addConversation: (customerId?: string, applicationIdorLeadId?: string, phoneNumber?: string, pni?: string, open: boolean = true) => {
      setConversations((p) => [...p, { customer_id: customerId, application_id_or_lead_id: applicationIdorLeadId, phone_number: phoneNumber, pni, open }]);
      lastConversationChangeSource.current = LastConversationChangeSource.FRONTEND;
    },
    removeConversation: (customerId: string) => {
      setConversations((p) => [...p].filter(({ customer_id }) => customer_id !== customerId));
      lastConversationChangeSource.current = LastConversationChangeSource.FRONTEND;
    },
    maximizeConversation: (customer_id: string) => {
      setConversations((p) => [...p.map((c) => c.customer_id !== customer_id ? c : { ...c, open: true })]);
      lastConversationChangeSource.current = LastConversationChangeSource.FRONTEND;
    },
    minimizeConversation: (customer_id: string) => {
      setConversations((p) => [...p.map((c) => c.customer_id !== customer_id ? c : { ...c, open: false })]);
      lastConversationChangeSource.current = LastConversationChangeSource.FRONTEND;
    },
  }), [conversations, setConversations]);

  useEffect(() => {
    if (lastConversationChangeSource.current === LastConversationChangeSource.BACKEND) return;

    (async () => {
      const saveConversationRequestId = uuid();
      try {
        await api.message.saveConversationsMessageConversationsPost(conversations, { cancelToken: saveConversationRequestId, headers: { 'X-Request-ID': saveConversationRequestId } });
      } catch (e) {
        if (e?.name === 'AbortError') return;

        logger.error(e, { source: 'MessagingProvider', description: 'Save conversation', saveConversationRequestId });
      }
    })();
  }, [api, conversations]);

  useEffect(() => {
    if (!isAuthenticated || !platform || !settings.zoordinates || isDesktopDialmakerApp) return () => {};

    const requestId = uuid();

    let es: EventSourcePolyfill;
    (async () => {
      const token = await getAccessToken();

      if (!token) return;

      es = new EventSourcePolyfill(STREAM_URL, { headers: {
        Authorization: `Bearer ${token}`,
        'X-Request-ID': requestId,
        'X-Zoordinates': settings.zoordinates,
      } });
      es.onmessage = (e) => {
        const payload = JSON.parse(e.data);
        setConversations((p) => isEqual(p, payload) ? p : payload);
        lastConversationChangeSource.current = LastConversationChangeSource.BACKEND;
      };
    })();

    return () => {
      if (es) es.close();
    };
  }, [getAccessToken, settings.zoordinates, isAuthenticated, platform]);

  return (
    <MessagingContext.Provider value={value}>
      {children}
    </MessagingContext.Provider>
  );
};

export default MessagingContext;
