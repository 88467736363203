import type { FC } from 'react';
import { createContext, useMemo } from 'react';

import PropTypes from 'prop-types';

import { Country, Currency } from 'src/api/zrm/zrmApi';
import useSettings from 'src/hooks/useSettings';
import { currencySuffix, defaultCurrency } from 'src/utils/defaultCurrency';
import { defaultTimezone as getDefaultTimezone } from 'src/utils/defaultTimezone';
import { decimalSeparator, formatCurrency, formatNumber, formatPercent } from 'src/utils/formatNumber';
import { formatPNI } from 'src/utils/formatPNI';
import { formatPostalCode } from 'src/utils/formatPostalCode';
import { getLocale } from 'src/utils/getLocale';
import { compareStrings } from 'src/utils/sortString';

export interface Utils {
  compareStrings: (a: string, b: string) => number;
  formatNumber: (n: number, d?: number) => string;
  formatCurrency: (v: number, currency?: Currency, decimals?: number) => string;
  formatPercent: (p: number | string, d?: number) => string;
  defaultCurrency: Currency;
  defaultCurrencySuffix: string;
  getCurrencySuffix: (c: Currency) => string;
  getCurrency: (country: Country) => Currency;
  defaultTimezone: string;
  formatPNI: (pni: string) => string;
  formatPostalCode: (postalCode: string) => string;
  decimalSeparator: string;
  locale: string;
}

const UtilsContext = createContext<Utils>({
  compareStrings: () => 0,
  formatNumber: (n: number) => n.toString(),
  formatCurrency: (v: number) => v.toString(),
  formatPercent: (p: number | string) => p.toString(),
  defaultCurrency: Currency.SEK,
  defaultCurrencySuffix: 'kr',
  getCurrencySuffix: (c) => c,
  getCurrency: defaultCurrency,
  defaultTimezone: 'Europe/Stockholm',
  formatPNI: (pni) => pni,
  formatPostalCode: (postalCode) => postalCode,
  decimalSeparator: '.',
  locale: 'sv-SE',
});

export const UtilsProvider: FC = (props) => {
  const { children } = props;
  const { country } = useSettings();

  const utils: Utils = useMemo(() => {
    const defaultCurrencyValue = defaultCurrency(country);
    const defaultTimezone = getDefaultTimezone(country);

    return {
      compareStrings: (a, b) => compareStrings(a, b, country),
      formatNumber: (n, d) => formatNumber(n, country, d),
      formatCurrency: (v, currency?: Currency, decimals?: number) => formatCurrency(v, country, currency || defaultCurrencyValue, decimals),
      formatPercent: (p, d?) => formatPercent(p, country, d),
      defaultCurrency: defaultCurrencyValue,
      defaultCurrencySuffix: currencySuffix(country, defaultCurrencyValue),
      getCurrencySuffix: (c) => currencySuffix(country, c),
      getCurrency: defaultCurrency,
      defaultTimezone,
      formatPNI: (pni) => formatPNI(pni, country),
      formatPostalCode: (postalCode) => formatPostalCode(postalCode, country),
      decimalSeparator: decimalSeparator(country),
      locale: getLocale(country),
    };
  }, [country]);

  return (
    <UtilsContext.Provider value={utils}>
      {children}
    </UtilsContext.Provider>
  );
};

UtilsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const UtilsConsumer = UtilsContext.Consumer;

export default UtilsContext;
