import { FC, lazy, Suspense } from 'react';

import { Grid, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { ZCountry, Zoordinates } from 'src/api/zrm';
import { parseZoordinates, translateZoordinates, zOfficeToString } from 'src/utils/zoordinates';

const FlagLoader = (Component) => (props) => (
  <Suspense fallback={null}>
    <Component {...props} />
  </Suspense>
);

const SEFlag = FlagLoader(lazy(() => import('src/icons/SEFlag')));
const NOFlag = FlagLoader(lazy(() => import('src/icons/NOFlag')));

interface RenderedZoordinatesProps {
  zoordinates: Zoordinates | string;
  small?: boolean;
}

const ZoordinatesVisual: FC<RenderedZoordinatesProps> = (props: RenderedZoordinatesProps) => {
  const { t } = useTranslation();
  /* eslint-disable-next-line prefer-const */
  let { zoordinates: z, small = false } = props;

  if (typeof z === 'string') z = parseZoordinates(z);

  return (
    <Grid
      container
      spacing={1}
    >
      {z ? (
        <>
          <Grid
            item
          >
            {z.Country === ZCountry.SE ? (
              <SEFlag
                viewBox="0 0 1280 800"
                width={58}
              />
            ) : null}
            {z.Country === ZCountry.NO ? <NOFlag /> : null}
          </Grid>
          <Grid item>
            <Typography>
              {`${t(translateZoordinates.zProductToProduct(z.Product))} - ${z.Department}${small ? '' : ` - ${z.EmploymentType} - ${zOfficeToString(z.Office)}`}`}
            </Typography>
          </Grid>
        </>
      ) : null}
    </Grid>
  );
};

ZoordinatesVisual.propTypes = {
  /* @ts-ignore */
  zoordinates: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  small: PropTypes.bool,
};

export { ZoordinatesVisual as Zoordinates };
