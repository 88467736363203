import { useEffect, useMemo, useState } from 'react';

import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { v4 } from 'uuid';

import { Employee } from 'src/api/zrm';
import { Stage } from 'src/types/stage';
import logger from 'src/utils/logger';

import useApi from '../useApi';

const useFetchAllEmployees = () => {
  const { api } = useApi();
  const { t } = useTranslation();

  const [employees, setEmployees] = useState<Employee[]>([]);
  const [employeesStage, setEmployeesStage] = useState<Stage>(Stage.pending);

  const refreshEmployees = useMemo(() => async (requestId = v4()) => {
    setEmployeesStage(Stage.pending);

    try {
      const response = await api.employee.getAllEmployeesEmployeeAllGet(
        { without_deactivated: false },
        { cancelToken: requestId, headers: { 'X-Request-ID': requestId } },
      );

      setEmployees(response.data);
      setEmployeesStage(Stage.fulfilled);
    } catch (e) {
      if (e?.name === 'AbortError') return;

      setEmployeesStage(Stage.rejected);
      logger.error(e, { source: 'useFetchAllEmployees', description: 'Cannot get list of employees', requestId });
      toast.error(t('Cannot get list of employees').toString());
    }
  }, [api]);

  useEffect(() => {
    const requestId = v4();

    refreshEmployees(requestId);

    return () => { api.abortRequest(requestId); };
  }, [refreshEmployees]);

  return { employees, employeesStage, refreshEmployees };
};

export default useFetchAllEmployees;
