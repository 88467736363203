import { FC, useEffect, useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { AppBar, Box, IconButton, Toolbar } from '@material-ui/core';
import { experimentalStyled } from '@material-ui/core/styles';

import FridayLogo from 'src/components/FridayLogo';
import Logo from 'src/components/Logo';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import useAuth from 'src/hooks/useAuth';
import MenuIcon from 'src/icons/Menu';

import AccountPopover from './AccountPopover';
import ContentSearch from './ContentSearch';
import LanguagePopover from './LanguagePopover';
import NotificationsPopover from './notifications/NotificationsPopover';

import type { AppBarProps } from '@material-ui/core';

interface DashboardNavbarProps extends AppBarProps {
  onSidebarMobileOpen?: () => void;
}

export const DashboardNavbarRoot = experimentalStyled(AppBar)(
  ({ theme }) => (
    {
      ...(
        theme.palette.mode === 'light' && {
          backgroundColor: theme.palette.primary.main,
          boxShadow: 'none',
          color: theme.palette.primary.contrastText,
        }
      ),
      ...(
        theme.palette.mode === 'dark' && {
          backgroundColor: theme.palette.background.paper,
          borderBottom: `1px solid ${theme.palette.divider}`,
          boxShadow: 'none',
        }
      ),
      zIndex: theme.zIndex.drawer + 100,
    }
  ),
);

const getIsFriday = () => new Date().getDay() === 5 && new Date().getHours() >= 15;

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
  const { onSidebarMobileOpen, ...other } = props;
  const [isFriday, setIsFriday] = useState(getIsFriday());
  const { platform } = useAuth();

  const isBank = useMemo(() => platform === AuthenticationPlatform.JWT, [platform]);

  useEffect(() => {
    const i = setInterval(() => {
      if (getIsFriday()) setIsFriday((p) => p || true);
    }, 60000);

    return () => { clearInterval(i); };
  }, [setIsFriday]);

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none',
            },
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <RouterLink to="/">
          {isFriday ? (
            <FridayLogo
              height={45}
              width={45}
              sx={{
                display: {
                  lg: 'inline',
                  xs: 'none',
                },
              }}
            />
          ) : (
            <Logo
              sx={{
                display: {
                  lg: 'inline',
                  xs: 'none',
                },
                height: 40,
                width: 40,
              }}
            />
          )}
        </RouterLink>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2,
          }}
        />
        <LanguagePopover />
        <Box sx={{ ml: 1 }}>
          <ContentSearch />
        </Box>
        {!isBank && (
          <Box sx={{ ml: 1 }}>
            <NotificationsPopover />
          </Box>
        )}
        <Box sx={{ ml: 2 }}>
          <AccountPopover />
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

export default DashboardNavbar;
