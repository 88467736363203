import type { FC } from 'react';
import { useEffect, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { Box, Divider, Drawer, Typography } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

import { Zoordinates as ZoordinatesComponent } from 'src/components/zoordinates';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import useAuth from 'src/hooks/useAuth';
import useRoutes from 'src/hooks/useRoutes';
import useSettings from 'src/hooks/useSettings';
import useUser from 'src/hooks/useUser';
import { ZensUser } from 'src/types/auth';
import { parseZoordinates } from 'src/utils/zoordinates';

import Logo from '../Logo';
import NavSection from '../NavSection';
import Scrollbar from '../Scrollbar';

import type { Theme } from '@material-ui/core';

interface DashboardSidebarProps {
  onMobileClose: () => void;
  openMobile: boolean;
  mobileOnly?: boolean;
  showUserInfo?: boolean;
}

const width = 280;

const DashboardSidebar: FC<DashboardSidebarProps> = (props) => {
  const { onMobileClose, openMobile, mobileOnly, showUserInfo } = props;

  const location = useLocation();
  const user = useUser();
  const { settings: { zoordinates: zoordinatesString }, product, country } = useSettings();
  const zoordinates = useMemo(() => parseZoordinates(zoordinatesString), [zoordinatesString]);
  const { t } = useTranslation();
  const { platform } = useAuth();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));
  const { isBank, isCustomer } = useMemo(() => ({ isBank: platform === AuthenticationPlatform.JWT, isCustomer: platform === AuthenticationPlatform.BankIdNo }), [platform]);

  const { routes } = useRoutes();

  useEffect(() => {
    if (openMobile && onMobileClose) onMobileClose();
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: mobileOnly ? 'flex' : 'none',
              xs: 'flex',
            },
            justifyContent: 'center',
            p: 2,
          }}
        >
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40,
              }}
            />
          </RouterLink>
        </Box>
        {showUserInfo && (
          <>
            <Box sx={{ px: 2, py: 1 }}>
              <Box
                sx={{
                  alignItems: 'center',
                  backgroundColor: 'background.default',
                  borderRadius: 1,
                  display: 'flex',
                  overflow: 'hidden',
                  padding: 1,
                }}
              >
                <Box sx={{ ml: 1 }}>
                  <Typography
                    color="textPrimary"
                    variant="subtitle2"
                  >
                    {(user as ZensUser).name ?? `${user.first_name} + ' ' ${user.last_name}`}
                  </Typography>
                  <Typography
                    color="textSecondary"
                    variant="body2"
                    component="div"
                  >
                    {isBank ? `${country} | ${t(product)}` : (
                      <ZoordinatesComponent
                        zoordinates={zoordinates}
                        small
                      />
                    )}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Divider />
          </>
        )}
        <Box sx={{ px: 2, py: 2 }}>
          {routes.filter((section) => !section.hidden)
            .reduce((acc, curr) => [...acc, ...curr.children], [])
            .map((section, index) => (
              <NavSection
              /* eslint-disable-next-line react/no-array-index-key */
                key={section.title + location.pathname + index}
                pathname={location.pathname}
                sx={{
                  '& + &': {
                    mt: 1,
                  },
                }}
                items={section.children ?? []}
                {...section}
                title={t(section.title ?? '')}
              />
            ))}
        </Box>
        <Box sx={{ height: 64 }} />
        <Box sx={{ p: 2, position: 'fixed', bottom: 0, backgroundColor: 'background.paper', width, opacity: '0.9' }}>
          <Typography
            color="textPrimary"
            variant="subtitle2"
          >
            {(isCustomer || isBank) ? 'Zensum' : 'ZRM'}
          </Typography>
          <Typography
            color="textSecondary"
            variant="body2"
          >
            {isCustomer ? t('My Pages') : t('The CRM for Zensum')}
          </Typography>
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp && !mobileOnly) return (
    <Drawer
      anchor="left"
      open
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          height: 'calc(100% - 64px) !important',
          top: '64px !Important',
          overflow: 'hidden',
          width,
        },
      }}
      variant="permanent"
    >
      {content}
    </Drawer>
  );

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width,
        },
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

export default DashboardSidebar;
