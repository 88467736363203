import './i18n';
import './utils/overwriteStorageIfNeeded';

import { FC, lazy, memo, Suspense, useMemo } from 'react';
import { useRoutes } from 'react-router-dom';

import { CssBaseline } from '@material-ui/core';
import { Toaster } from 'react-hot-toast';

import Conversations from 'src/components/messaging/Conversations';
import SplashScreen from 'src/components/SplashScreen';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import { RoutesProvider } from 'src/contexts/Routes';
import useRoutesHook from 'src/hooks/useRoutes';
import useScrollReset from 'src/hooks/useScrollReset';

import LoadingScreen from './components/LoadingScreen';
import { ThemeSettingsProvider } from './contexts/ThemeSettingsContext';
import useAuth from './hooks/useAuth';

const Routes: FC = memo(() => {
  const { routes } = useRoutesHook();
  const content = useRoutes(routes);

  return content;
});

const Loadable = (Component: FC<any>) => (props: any) => (
  <Suspense fallback={<LoadingScreen />}>
    <Component {...props} />
  </Suspense>
);

const NotificationsProvider = Loadable(lazy(() => import('./contexts/NotificationsProvider')));
const MessagingProvider = Loadable(lazy(() => import('./contexts/MessagingProvider')));

const App: FC = () => {
  const { completeState, platform } = useAuth();
  const authComplete = useMemo(() => completeState === 'complete' || [AuthenticationPlatform.JWT, AuthenticationPlatform.BankIdNo].includes(platform), [completeState, platform]);

  const isCustomer = platform === AuthenticationPlatform.BankIdNo;

  useScrollReset();

  return (
    <ThemeSettingsProvider>
      <CssBaseline />
      {!authComplete && <SplashScreen />}
      {authComplete && <Toaster position="bottom-left" />}
      {authComplete && isCustomer && (
        <RoutesProvider>
          <Routes />
        </RoutesProvider>
      )}
      {authComplete && !isCustomer && (
        <MessagingProvider>
          <NotificationsProvider>
            <Toaster position="bottom-left" />
            <RoutesProvider>
              <Routes />
            </RoutesProvider>
          </NotificationsProvider>
          <Conversations />
        </MessagingProvider>
      )}
    </ThemeSettingsProvider>
  );
};

export default App;
