import { FC, useMemo } from 'react';

import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Label from 'src/components/Label';

const ColoredLabel = ({ text, color }: { text: string, color: string }): JSX.Element => (
  <Label color={color as any}>
    {text}
  </Label>
);

export const SubStatusLabel: FC<{ substatus: string }> = ({ substatus }) => {
  const { t } = useTranslation();

  const { color, text } = useMemo(() => {
    switch (substatus) {
      case 'WAITING_FOR_CUSTOMER_ACCEPTANCE':
        return {
          color: 'warning',
          text: t('Waiting for customer sign'),
        };
      case 'ACTIVE':
        return {
          color: 'success',
          text: t('Active'),
        };
      case 'WAITING_FOR_BANK':
        return {
          color: 'success',
          text: t('Waiting for bank'),
        };
      case 'CANCELED':
      case 'DISCARDED':
        return {
          color: 'warning',
          text: t('Canceled'),
        };
      case 'INACTIVE_MANDATE':
        return {
          color: 'warning',
          text: t('Inactive mandate'),
        };
      default:
        return {
          color: 'warning',
          text: t(substatus),
        };
    }
  }, [t, substatus]);

  return (
    <ColoredLabel
      text={text}
      color={color}
    />
  );
};

SubStatusLabel.propTypes = {
  substatus: PropTypes.string,
};

export const StatusLabel: FC<{ status: string }> = ({ status }) => {
  const { t } = useTranslation();

  const { color, text } = useMemo(() => {
    switch (status) {
      case 'INACTIVE':
        return {
          color: 'error',
          text: t('Inactive'),
        };
      case 'ACTIVE':
        return {
          color: 'success',
          text: t('Active'),
        };
      default:
        return {
          color: 'warning',
          text: t(status),
        };
    }
  }, [t, status]);

  return (
    <ColoredLabel
      text={text}
      color={color}
    />
  );
};

StatusLabel.propTypes = {
  status: PropTypes.string,
};
