import { FC, ReactNode } from 'react';

import {
  Box,
  Card as MuiCard,
  CardContent,
  CardHeader,
} from '@material-ui/core';
import { SxProps } from '@material-ui/system';

import LoadingOverlay from './LoadingOverlay';

interface CardProps {
  title?: string | ReactNode;
  loading?: boolean;
  children?: ReactNode;
  action?: ReactNode;
  sx?: SxProps<any>;
  cardContentSx?: SxProps<any>;
}

const Card: FC<CardProps> = ({ title = '', loading = false, children, action, sx, cardContentSx, ...other }) => (
  <MuiCard
    {...other}
    sx={{ position: 'relative', overflow: 'hidden', p: 0, margin: 0, ...sx }}
  >
    <CardContent
      sx={{ px: 3, ...cardContentSx }}
    >
      {title && (
        <CardHeader
          title={title}
          action={action}
          sx={{ p: 0, mb: 2 }}
        />
      )}
      <Box sx={{ px: 1 }}>
        {children}
      </Box>
    </CardContent>
    {loading && <LoadingOverlay zIndex={2} />}
  </MuiCard>
);

export default Card;
