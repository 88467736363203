/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Account {
  /** @example 1234567 */
  account_number?: string;

  /** @example 23 */
  bank_id?: number;

  /** @example SEB */
  bank_name?: string;

  /** @example 1234 */
  clearing_number?: string;
}

export type BaseHouseProperty = BaseProperty & { cadastral_designation?: string };

export interface BaseProperty {
  /**
   * The postal area city
   * @example Bandhagen
   */
  city?: string;

  /** Country to do the valuation in */
  country?: SupportedCountries;

  /**
   * The ID from e.g UC
   * @example 3e8eed4f-fe88-4068-8881-fd4a6ae1e760
   */
  external_id?: string;

  /** @example 9999 */
  monthly_cost?: number;
  other_owner?: boolean;

  /**
   * The postal code of the property
   * @example 12453
   */
  post_code?: string;

  /** @example HOUSE */
  property_type?: string;

  /** @example 58 */
  square_meter_living_area?: number;

  /**
   * The street address of the property you wish to get info about
   * @example Storgatan 11A
   */
  street_address?: string;

  /** @example 3600000 */
  value?: number;
}

export type CondominiumProperty = BaseProperty & {
  apartment_number?: string;
  balcony_type?: string;
  elevator?: boolean;
  floor?: number;
  organization_number?: string;
  property_type?: "CONDOMINIUM";
};

export type Customer = Person &
  (any | any | any) & {
    customer_id: string;
    email?: string | null;
    phone_number?: string | null;
    sverker_id?: string | null;
  };

export type CustomerCreation = (any | any) & {
  account?: Account;
  country?: SupportedCountries;
  email?: string | null;
  phone_number?: string | null;
  pni: string;
  sverker_id?: string | null;
  [key: string]: any;
};

/**
 * Actions that can be forced
 */
export interface ForceActions {
  /**
   * The ID of the last credit check.
   * @format uuid
   */
  CREDIT_CHECK?: string;

  /**
   * The ID of the last ID check.
   * @format uuid
   */
  ID_CHECK?: string;
}

export type HouseProperty = BaseHouseProperty & { property_type?: "HOUSE" };

/**
 * PEP check response
 */
export interface PEPCheck {
  /** @example true */
  is_PEP?: boolean;

  /**
   * A low quality means a high likelyhood of person being a politically exposed person
   * @example 0
   */
  quality?: number;
}

export interface Person {
  /** @example Ankeborg */
  city?: string;

  /** Supported countries */
  country: SupportedCountries;

  /** @example Pelle */
  first_name: string;

  /** @example Karlsson */
  last_name: string;

  /** @example 194102137785 */
  pni: string;

  /** @example 111 21 */
  postal_code?: string;

  /** @example Storgatan 1 */
  street_address?: string;
  [key: string]: any;
}

export type Property = CondominiumProperty | HouseProperty | VacationHomeProperty;

export type Response = {
  actions: {
    action:
      | "CUSTOMER_ACCESSED"
      | "UC_ID_CHECK"
      | "UC_CREDIT_CHECK"
      | "EXPERIAN_ID_CHECK"
      | "EXPERIAN_CREDIT_CHECK"
      | "CUSTOMER_CREATED"
      | "CUSTOMER_UPDATED"
      | "DECRYPTED_CUSTOMER"
      | "STARTING_REQUEST"
      | "CM1_PEP_CHECK"
      | "PROPERTY_CHECK";
    level: "INFO" | "WARNING" | "DEBUG" | "ERROR";
    message: string;
    success: boolean;
    timestamp: string;
  }[];
  customer?: Customer;
  customers?: Customer[];
  force_actions?: ForceActions;
  message: string;
  person?: Person;
  property?: { property_id?: string } & Property;
};

/**
 * Supported countries
 * @example SE
 */
export enum SupportedCountries {
  SE = "SE",
  NO = "NO",
}

export type VacationHomeProperty = BaseHouseProperty & { property_type?: "VACATION_HOME" };

export namespace Admin {
  /**
   * No description
   * @name ApiGetDeleteSalt
   * @summary Get salt for creating signature for customer deletion
   * @request GET:/admin/delete-salt
   * @secure
   */
  export namespace ApiGetDeleteSalt {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = { salt?: string };
  }
}

export namespace BlockCustomerField {
  /**
   * No description
   * @name ApiBlockCustomerFieldManually
   * @request POST:/block-customer-field
   * @secure
   */
  export namespace ApiBlockCustomerFieldManually {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      blocked: boolean;
      country: string;
      field: "phone" | "pni" | "email";
      reason: string;
      values: string[];
    };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace CheckCustomerIdForBlocks {
  /**
   * No description
   * @name ApiCheckIfBlockedByCustomerId
   * @request POST:/check-customer-id-for-blocks
   * @secure
   */
  export namespace ApiCheckIfBlockedByCustomerId {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { customer_ids: string[]; phone?: boolean; pni?: boolean };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace CheckExternalBlock {
  /**
   * No description
   * @name ApiCheckExternalBlock
   * @request POST:/check-external-block
   * @secure
   */
  export namespace ApiCheckExternalBlock {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { country: string; customer_ids: string[]; reason: string };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace CheckPhoneOrPniOrEmailForBlocks {
  /**
   * No description
   * @name ApiCheckPhoneOrPniForBlocks
   * @request POST:/check-phone-or-pni-or-email-for-blocks
   * @secure
   */
  export namespace ApiCheckPhoneOrPniForBlocks {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      country: string;
      emails?: string[] | null;
      phone_numbers?: string[] | null;
      pnis?: string[] | null;
    };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Document {
  /**
   * @description Add a document to customer
   * @name ApiDocumentAddDocument
   * @request POST:/document
   * @secure
   */
  export namespace ApiDocumentAddDocument {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      customer_id: string;
      data: string;
      document_type: string;
      mime_type: string;
      name: string;
    };
    export type RequestHeaders = {};
    export type ResponseBody = { document_id?: string };
  }
  /**
   * @description Get a document for a customer
   * @name ApiDocumentGetDocument
   * @request POST:/document/get
   * @secure
   */
  export namespace ApiDocumentGetDocument {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { customer_id: string; document_id: string };
    export type RequestHeaders = {};
    export type ResponseBody = { data?: string; mime_type?: string; name?: string; timestamp?: string };
  }
  /**
   * @description Get a document metadata for a customer
   * @name ApiDocumentGetDocumentMeta
   * @request POST:/document/get/meta
   * @secure
   */
  export namespace ApiDocumentGetDocumentMeta {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { customer_id: string; document_id: string };
    export type RequestHeaders = {};
    export type ResponseBody = { mime_type?: string; name?: string; timestamp?: string };
  }
}

export namespace EmploymentTypes {
  /**
   * @description Get employment types per country
   * @name ApiGetEmploymentTypes
   * @request GET:/employment-types/{country}
   * @secure
   */
  export namespace ApiGetEmploymentTypes {
    export type RequestParams = { country: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }
}

export namespace EvCheck {
  /**
   * No description
   * @name ApiEvCheckFromPni
   * @request POST:/ev-check
   * @secure
   */
  export namespace ApiEvCheckFromPni {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { pnis: string[]; reason: string };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace GetCustomer {
  /**
   * @description Get a customer or customers, as a get but with a post....
   * @name ApiGetCustomerByPost
   * @request POST:/get-customer
   * @secure
   */
  export namespace ApiGetCustomerByPost {
    export type RequestParams = {};
    export type RequestQuery = { projection?: string };
    export type RequestBody = {
      country?: SupportedCountries;
      credit_check?: boolean;
      email?: string;
      force_action_event_id?: string;
      get_or_id_check?: boolean;
      id?: string;
      id_check?: boolean;
      ids?: string[];
      pep_check?: boolean;
      phone_check?: boolean;
      phone_number?: string;
      pni?: string;
      pnis?: string[];
      projection?: object;
      property_check?: boolean;
      reason: string;
      sverker_id?: string;
      sverker_ids?: string[];
      timestamp?: string;
    };
    export type RequestHeaders = {};
    export type ResponseBody = Response;
  }
}

export namespace GetEvents {
  /**
   * No description
   * @name ApiApiGetEvents
   * @request GET:/get-events
   * @secure
   */
  export namespace ApiApiGetEvents {
    export type RequestParams = {};
    export type RequestQuery = { customer_id: string; reason: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }
}

export namespace Gjeldsregisteret {
  /**
   * No description
   * @name ApiGetGjeldsregisteretUrlWithIdKollen
   * @summary Get an url for a Gjeldsregisteret iframe
   * @request POST:/gjeldsregisteret/idkollen/iframe
   * @secure
   */
  export namespace ApiGetGjeldsregisteretUrlWithIdKollen {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { css_url: string; customer_token: string };
    export type RequestHeaders = {};
    export type ResponseBody = { url: string };
  }
  /**
   * No description
   * @name ApiGetGjeldsregisteretUrl
   * @summary Get an url for a Gjeldsregisteret iframe
   * @request GET:/gjeldsregisteret/iframe
   * @secure
   */
  export namespace ApiGetGjeldsregisteretUrl {
    export type RequestParams = {};
    export type RequestQuery = { css: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = { url: string };
  }
}

export namespace IdCheck {
  /**
   * No description
   * @name ApiLookupByPni
   * @request GET:/id-check
   * @secure
   */
  export namespace ApiLookupByPni {
    export type RequestParams = {};
    export type RequestQuery = {
      pni: string;
      country?: SupportedCountries;
      projection?: string;
      pep_check?: boolean;
      reason?: string;
    };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Response;
  }
}

export namespace IdChecks {
  /**
   * No description
   * @name ApiLookupByPnis
   * @request POST:/id-checks
   * @secure
   */
  export namespace ApiLookupByPnis {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { pep_check?: boolean; pnis: string[]; reason: string };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace InsertCustomerEvent {
  /**
   * @description Add an event without using the rest of the logic.
   * @name ApiInsertEvent
   * @request POST:/insert-customer-event
   * @secure
   */
  export namespace ApiInsertEvent {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      customer_id: string;
      data: object;
      event: "CUSTOMER_CREATED" | "CUSTOMER_UPDATED";
      event_id?: string;
      reason?: string;
      timestamp: string;
      user_id: string;
    };
    export type RequestHeaders = {};
    export type ResponseBody = { event_id?: string; success: boolean };
  }
}

export namespace LastUpdated {
  /**
   * @description Get last_updated date for customers
   * @name ApiGetLastUpdated
   * @request POST:/last-updated
   * @secure
   */
  export namespace ApiGetLastUpdated {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = string[];
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }
}

export namespace ManualBlock {
  /**
   * @description Manually block a customer, e.g from creating new applications
   * @name ApiManuallyBlockCustomer
   * @request POST:/manual-block
   * @secure
   */
  export namespace ApiManuallyBlockCustomer {
    export type RequestParams = {};
    export type RequestQuery = { projection?: string };
    export type RequestBody = { customer_id: string; manual_block: true; reason: string };
    export type RequestHeaders = {};
    export type ResponseBody = Response;
  }
}

export namespace MongoPerformance {
  /**
   * @description Get a customer or customers, as a get but with a post....
   * @name ApiCheckMongoPerformance
   * @request POST:/mongo-performance
   * @secure
   */
  export namespace ApiCheckMongoPerformance {
    export type RequestParams = {};
    export type RequestQuery = { projection?: string };
    export type RequestBody = { ids?: string[] };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace PepCheck {
  /**
   * @description Preform a pep check on an applicant
   * @name ApiDoPepCheck
   * @request GET:/pep-check
   * @secure
   */
  export namespace ApiDoPepCheck {
    export type RequestParams = {};
    export type RequestQuery = { pni: string; country?: SupportedCountries; projection?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Response;
  }
}

export namespace PepCheckEvent {
  /**
   * @description Preform a pep check on an applicant
   * @name ApiGetPepCheckEvent
   * @request GET:/pep-check-event
   * @secure
   */
  export namespace ApiGetPepCheckEvent {
    export type RequestParams = {};
    export type RequestQuery = { event_id?: string; pni?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = object;
  }
}

export namespace PollExternalBlockCheck {
  /**
   * No description
   * @name ApiPollExternalBlockCheck
   * @request POST:/poll-external-block-check
   * @secure
   */
  export namespace ApiPollExternalBlockCheck {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { job_id: string };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Property {
  /**
   * @description Get a property
   * @name ApiPropertyGetProperty
   * @request GET:/property
   * @secure
   */
  export namespace ApiPropertyGetProperty {
    export type RequestParams = {};
    export type RequestQuery = { id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Response;
  }
  /**
   * @description Save a property
   * @name ApiPropertySaveProperty
   * @request POST:/property
   * @secure
   */
  export namespace ApiPropertySaveProperty {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = Record<string, any>;
    export type RequestHeaders = {};
    export type ResponseBody = Response;
  }
  /**
   * @description Duplicate a property
   * @name ApiPropertyDuplicateProperty
   * @request GET:/property/duplicate
   * @secure
   */
  export namespace ApiPropertyDuplicateProperty {
    export type RequestParams = {};
    export type RequestQuery = { id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Response;
  }
  /**
   * @description Does ID exist in db?
   * @name ApiPropertyPropertyExists
   * @request GET:/property/exists/{id}
   * @secure
   */
  export namespace ApiPropertyPropertyExists {
    export type RequestParams = { id: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = { exists?: boolean };
  }
}

export namespace ScheduleCheck {
  /**
   * @description Schedule a check for existing customer (id, pep, credit, etc.)
   * @name ApiApiScheduleChecks
   * @request POST:/schedule-check
   * @secure
   */
  export namespace ApiApiScheduleChecks {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      credit_check?: boolean;
      customer_id?: string;
      id_check?: boolean;
      pep_check?: boolean;
      phone_check?: boolean;
      pni?: string;
      property_check?: boolean;
      reason: string;
    };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace ScheduleExternalBlocks {
  /**
   * No description
   * @name ApiScheduleManualExternalBlockChecks
   * @request POST:/schedule-external-blocks
   * @secure
   */
  export namespace ApiScheduleManualExternalBlockChecks {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { country: string; customer_ids: string[]; reason: string };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace SearchCustomerId {
  /**
   * @description Get a customers ids
   * @name ApiGetCustomerIds
   * @request POST:/search-customer-id
   * @secure
   */
  export namespace ApiGetCustomerIds {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      country?: SupportedCountries | null;
      emails?: string[] | null;
      phone_numbers?: string[] | null;
      pnis?: string[] | null;
      return_dict?: boolean;
      sverker_ids?: string[] | null;
    };
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, any>;
  }
}

export namespace WhitelistPepCheck {
  /**
   * @description Whitelist pep check on an applicant
   * @name ApiWhitelistPepCheck
   * @request POST:/whitelist-pep-check
   * @secure
   */
  export namespace ApiWhitelistPepCheck {
    export type RequestParams = {};
    export type RequestQuery = { projection?: string };
    export type RequestBody = { customer_id: string; reason: string; whitelist: true };
    export type RequestHeaders = {};
    export type ResponseBody = Response;
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends globalThis.Response {
  data: D;
  error: E;
  requestId: string;
}

export interface HttpErrorResponse<E> extends globalThis.Response {
  error: E;
  requestId: string;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  private generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    // We need to generate new RequestID for every request
    let requestId = requestParams.headers["X-Request-ID"];
    if (!requestId) {
      requestId = this.generateUUID();
      requestParams.headers["X-Request-ID"] = requestId;
    }

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;
      r.requestId = requestId;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data as HttpErrorResponse<E>;
      return data;
    });
  };
}

/**
 * @title Customer Service
 * @version 1.0.0
 * @baseUrl /api
 * @contact <dev@zensum.se>
 *
 * Service for creating and managing customers
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  /**
   * @description Delete the customer from kdlt.
   *
   * @name ApiDeleteCustomer
   * @summary Delete customer
   * @request DELETE:/
   * @secure
   */
  apiDeleteCustomer = (query: { id: string; signature: string; reason: string }, params: RequestParams = {}) =>
    this.request<void, void>({
      path: `/`,
      method: "DELETE",
      query: query,
      secure: true,
      ...params,
    });

  /**
   * @description Retrieve information of customer. This information should only be requested when explicitly needed.
   *
   * @name ApiGetCustomer
   * @summary Retrieve customer
   * @request GET:/
   * @secure
   */
  apiGetCustomer = (
    query: {
      id?: string;
      ids?: string[];
      sverker_id?: string;
      sverker_ids?: string[];
      projection?: string;
      pni?: string;
      country?: SupportedCountries;
      reason: string;
      id_check?: boolean;
      credit_check?: boolean;
      phone_check?: boolean;
      property_check?: boolean;
      force_action_event_id?: string;
      email?: string;
      phone_number?: string;
      timestamp?: string;
      get_or_id_check?: boolean;
    },
    params: RequestParams = {},
  ) =>
    this.request<Response, any>({
      path: `/`,
      method: "GET",
      query: query,
      secure: true,
      format: "json",
      ...params,
    });

  /**
   * @description Request to service to create a customer.
   *
   * @name ApiCreateCustomer
   * @summary Create customer
   * @request POST:/
   * @secure
   */
  apiCreateCustomer = (
    data: {
      country?: SupportedCountries;
      credit_check?: boolean;
      data: CustomerCreation;
      id_check?: boolean;
      persist?: boolean;
      phone_check?: boolean;
      pni?: string;
      property_check?: boolean;
      reason: string;
    },
    query?: { projection?: string },
    params: RequestParams = {},
  ) =>
    this.request<Response, any>({
      path: `/`,
      method: "POST",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  /**
   * @description Request to service to update a customer.
   *
   * @name ApiUpdateCustomer
   * @summary Update customer
   * @request PUT:/
   * @secure
   */
  apiUpdateCustomer = (
    data: {
      country?: SupportedCountries;
      credit_check?: boolean;
      customer_id?: string;
      data: { account?: Account; email?: string | null; phone_number?: string | null; sverker_id?: string | null };
      force_actions?: ForceActions;
      get_or_create?: boolean;
      id_check?: boolean;
      no_output?: boolean;
      persist?: boolean;
      phone_check?: boolean;
      pni?: string;
      property_check?: boolean;
      reason: string;
      upsert?: boolean;
    },
    query?: { projection?: string },
    params: RequestParams = {},
  ) =>
    this.request<Response, any>({
      path: `/`,
      method: "PUT",
      query: query,
      body: data,
      secure: true,
      type: ContentType.Json,
      format: "json",
      ...params,
    });

  admin = {
    /**
     * No description
     *
     * @name ApiGetDeleteSalt
     * @summary Get salt for creating signature for customer deletion
     * @request GET:/admin/delete-salt
     * @secure
     */
    apiGetDeleteSalt: (params: RequestParams = {}) =>
      this.request<{ salt?: string }, any>({
        path: `/admin/delete-salt`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  blockCustomerField = {
    /**
     * No description
     *
     * @name ApiBlockCustomerFieldManually
     * @request POST:/block-customer-field
     * @secure
     */
    apiBlockCustomerFieldManually: (
      data: { blocked: boolean; country: string; field: "phone" | "pni" | "email"; reason: string; values: string[] },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/block-customer-field`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  checkCustomerIdForBlocks = {
    /**
     * No description
     *
     * @name ApiCheckIfBlockedByCustomerId
     * @request POST:/check-customer-id-for-blocks
     * @secure
     */
    apiCheckIfBlockedByCustomerId: (
      data: { customer_ids: string[]; phone?: boolean; pni?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/check-customer-id-for-blocks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  checkExternalBlock = {
    /**
     * No description
     *
     * @name ApiCheckExternalBlock
     * @request POST:/check-external-block
     * @secure
     */
    apiCheckExternalBlock: (
      data: { country: string; customer_ids: string[]; reason: string },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/check-external-block`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  checkPhoneOrPniOrEmailForBlocks = {
    /**
     * No description
     *
     * @name ApiCheckPhoneOrPniForBlocks
     * @request POST:/check-phone-or-pni-or-email-for-blocks
     * @secure
     */
    apiCheckPhoneOrPniForBlocks: (
      data: { country: string; emails?: string[] | null; phone_numbers?: string[] | null; pnis?: string[] | null },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/check-phone-or-pni-or-email-for-blocks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  document = {
    /**
     * @description Add a document to customer
     *
     * @name ApiDocumentAddDocument
     * @request POST:/document
     * @secure
     */
    apiDocumentAddDocument: (
      data: { customer_id: string; data: string; document_type: string; mime_type: string; name: string },
      params: RequestParams = {},
    ) =>
      this.request<{ document_id?: string }, any>({
        path: `/document`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get a document for a customer
     *
     * @name ApiDocumentGetDocument
     * @request POST:/document/get
     * @secure
     */
    apiDocumentGetDocument: (data: { customer_id: string; document_id: string }, params: RequestParams = {}) =>
      this.request<{ data?: string; mime_type?: string; name?: string; timestamp?: string }, any>({
        path: `/document/get`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get a document metadata for a customer
     *
     * @name ApiDocumentGetDocumentMeta
     * @request POST:/document/get/meta
     * @secure
     */
    apiDocumentGetDocumentMeta: (data: { customer_id: string; document_id: string }, params: RequestParams = {}) =>
      this.request<{ mime_type?: string; name?: string; timestamp?: string }, any>({
        path: `/document/get/meta`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  employmentTypes = {
    /**
     * @description Get employment types per country
     *
     * @name ApiGetEmploymentTypes
     * @request GET:/employment-types/{country}
     * @secure
     */
    apiGetEmploymentTypes: (country: string, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/employment-types/${country}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  evCheck = {
    /**
     * No description
     *
     * @name ApiEvCheckFromPni
     * @request POST:/ev-check
     * @secure
     */
    apiEvCheckFromPni: (data: { pnis: string[]; reason: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/ev-check`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  getCustomer = {
    /**
     * @description Get a customer or customers, as a get but with a post....
     *
     * @name ApiGetCustomerByPost
     * @request POST:/get-customer
     * @secure
     */
    apiGetCustomerByPost: (
      data: {
        country?: SupportedCountries;
        credit_check?: boolean;
        email?: string;
        force_action_event_id?: string;
        get_or_id_check?: boolean;
        id?: string;
        id_check?: boolean;
        ids?: string[];
        pep_check?: boolean;
        phone_check?: boolean;
        phone_number?: string;
        pni?: string;
        pnis?: string[];
        projection?: object;
        property_check?: boolean;
        reason: string;
        sverker_id?: string;
        sverker_ids?: string[];
        timestamp?: string;
      },
      query?: { projection?: string },
      params: RequestParams = {},
    ) =>
      this.request<Response, any>({
        path: `/get-customer`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  getEvents = {
    /**
     * No description
     *
     * @name ApiApiGetEvents
     * @request GET:/get-events
     * @secure
     */
    apiApiGetEvents: (query: { customer_id: string; reason: string }, params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/get-events`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  gjeldsregisteret = {
    /**
     * No description
     *
     * @name ApiGetGjeldsregisteretUrlWithIdKollen
     * @summary Get an url for a Gjeldsregisteret iframe
     * @request POST:/gjeldsregisteret/idkollen/iframe
     * @secure
     */
    apiGetGjeldsregisteretUrlWithIdKollen: (
      data: { css_url: string; customer_token: string },
      params: RequestParams = {},
    ) =>
      this.request<{ url: string }, any>({
        path: `/gjeldsregisteret/idkollen/iframe`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @name ApiGetGjeldsregisteretUrl
     * @summary Get an url for a Gjeldsregisteret iframe
     * @request GET:/gjeldsregisteret/iframe
     * @secure
     */
    apiGetGjeldsregisteretUrl: (query: { css: string }, params: RequestParams = {}) =>
      this.request<{ url: string }, any>({
        path: `/gjeldsregisteret/iframe`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  idCheck = {
    /**
     * No description
     *
     * @name ApiLookupByPni
     * @request GET:/id-check
     * @secure
     */
    apiLookupByPni: (
      query: { pni: string; country?: SupportedCountries; projection?: string; pep_check?: boolean; reason?: string },
      params: RequestParams = {},
    ) =>
      this.request<Response, any>({
        path: `/id-check`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  idChecks = {
    /**
     * No description
     *
     * @name ApiLookupByPnis
     * @request POST:/id-checks
     * @secure
     */
    apiLookupByPnis: (data: { pep_check?: boolean; pnis: string[]; reason: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/id-checks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  insertCustomerEvent = {
    /**
     * @description Add an event without using the rest of the logic.
     *
     * @name ApiInsertEvent
     * @request POST:/insert-customer-event
     * @secure
     */
    apiInsertEvent: (
      data: {
        customer_id: string;
        data: object;
        event: "CUSTOMER_CREATED" | "CUSTOMER_UPDATED";
        event_id?: string;
        reason?: string;
        timestamp: string;
        user_id: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<{ event_id?: string; success: boolean }, any>({
        path: `/insert-customer-event`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  lastUpdated = {
    /**
     * @description Get last_updated date for customers
     *
     * @name ApiGetLastUpdated
     * @request POST:/last-updated
     * @secure
     */
    apiGetLastUpdated: (data: string[], params: RequestParams = {}) =>
      this.request<Record<string, any>, any>({
        path: `/last-updated`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  manualBlock = {
    /**
     * @description Manually block a customer, e.g from creating new applications
     *
     * @name ApiManuallyBlockCustomer
     * @request POST:/manual-block
     * @secure
     */
    apiManuallyBlockCustomer: (
      data: { customer_id: string; manual_block: true; reason: string },
      query?: { projection?: string },
      params: RequestParams = {},
    ) =>
      this.request<Response, any>({
        path: `/manual-block`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  mongoPerformance = {
    /**
     * @description Get a customer or customers, as a get but with a post....
     *
     * @name ApiCheckMongoPerformance
     * @request POST:/mongo-performance
     * @secure
     */
    apiCheckMongoPerformance: (data: { ids?: string[] }, query?: { projection?: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/mongo-performance`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  pepCheck = {
    /**
     * @description Preform a pep check on an applicant
     *
     * @name ApiDoPepCheck
     * @request GET:/pep-check
     * @secure
     */
    apiDoPepCheck: (
      query: { pni: string; country?: SupportedCountries; projection?: string },
      params: RequestParams = {},
    ) =>
      this.request<Response, any>({
        path: `/pep-check`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  pepCheckEvent = {
    /**
     * @description Preform a pep check on an applicant
     *
     * @name ApiGetPepCheckEvent
     * @request GET:/pep-check-event
     * @secure
     */
    apiGetPepCheckEvent: (query?: { event_id?: string; pni?: string }, params: RequestParams = {}) =>
      this.request<object, any>({
        path: `/pep-check-event`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),
  };
  pollExternalBlockCheck = {
    /**
     * No description
     *
     * @name ApiPollExternalBlockCheck
     * @request POST:/poll-external-block-check
     * @secure
     */
    apiPollExternalBlockCheck: (data: { job_id: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/poll-external-block-check`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  property = {
    /**
     * @description Get a property
     *
     * @name ApiPropertyGetProperty
     * @request GET:/property
     * @secure
     */
    apiPropertyGetProperty: (query: { id: string }, params: RequestParams = {}) =>
      this.request<Response, any>({
        path: `/property`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Save a property
     *
     * @name ApiPropertySaveProperty
     * @request POST:/property
     * @secure
     */
    apiPropertySaveProperty: (data: Record<string, any>, params: RequestParams = {}) =>
      this.request<Response, any>({
        path: `/property`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Duplicate a property
     *
     * @name ApiPropertyDuplicateProperty
     * @request GET:/property/duplicate
     * @secure
     */
    apiPropertyDuplicateProperty: (query: { id: string }, params: RequestParams = {}) =>
      this.request<Response, any>({
        path: `/property/duplicate`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * @description Does ID exist in db?
     *
     * @name ApiPropertyPropertyExists
     * @request GET:/property/exists/{id}
     * @secure
     */
    apiPropertyPropertyExists: (id: string, params: RequestParams = {}) =>
      this.request<{ exists?: boolean }, any>({
        path: `/property/exists/${id}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
  scheduleCheck = {
    /**
     * @description Schedule a check for existing customer (id, pep, credit, etc.)
     *
     * @name ApiApiScheduleChecks
     * @request POST:/schedule-check
     * @secure
     */
    apiApiScheduleChecks: (
      data: {
        credit_check?: boolean;
        customer_id?: string;
        id_check?: boolean;
        pep_check?: boolean;
        phone_check?: boolean;
        pni?: string;
        property_check?: boolean;
        reason: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/schedule-check`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  scheduleExternalBlocks = {
    /**
     * No description
     *
     * @name ApiScheduleManualExternalBlockChecks
     * @request POST:/schedule-external-blocks
     * @secure
     */
    apiScheduleManualExternalBlockChecks: (
      data: { country: string; customer_ids: string[]; reason: string },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/schedule-external-blocks`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  };
  searchCustomerId = {
    /**
     * @description Get a customers ids
     *
     * @name ApiGetCustomerIds
     * @request POST:/search-customer-id
     * @secure
     */
    apiGetCustomerIds: (
      data: {
        country?: SupportedCountries | null;
        emails?: string[] | null;
        phone_numbers?: string[] | null;
        pnis?: string[] | null;
        return_dict?: boolean;
        sverker_ids?: string[] | null;
      },
      params: RequestParams = {},
    ) =>
      this.request<Record<string, any>, any>({
        path: `/search-customer-id`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  whitelistPepCheck = {
    /**
     * @description Whitelist pep check on an applicant
     *
     * @name ApiWhitelistPepCheck
     * @request POST:/whitelist-pep-check
     * @secure
     */
    apiWhitelistPepCheck: (
      data: { customer_id: string; reason: string; whitelist: true },
      query?: { projection?: string },
      params: RequestParams = {},
    ) =>
      this.request<Response, any>({
        path: `/whitelist-pep-check`,
        method: "POST",
        query: query,
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
}
