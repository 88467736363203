import { FC } from 'react';

import { Button, Grid, Link, Typography, useTheme } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';

import Card from 'src/components/Card';
import { useIframeUtils } from 'src/contexts/IframeUtilsContext';
import { useCustomerLogin } from 'src/customerPages/context/login/CustomerLoginContext';

interface LoginBoxProps {
  isGr?: boolean;
}

const LoginBox: FC<LoginBoxProps> = (props) => {
  const { isGr } = props;

  const { t } = useTranslation();
  const theme = useTheme();
  const { heightBaseElementRef } = useIframeUtils();
  const { login } = useCustomerLogin();

  return (
    <Card>
      <Grid
        container
        spacing={2}
        ref={heightBaseElementRef}
        sx={{ textAlign: 'center' }}
      >
        <Grid
          item
          xs={12}
        >
          <Typography variant="h6">{isGr ? t('Share your debt register') : t('Login with BankID to see your offers and Gjeldsregisteret')}</Typography>
          {isGr && (
            <Typography>
              {t('If you share your debt information from the Debt Register, the chance of getting good loan offers increases.')}
            </Typography>
          )}
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: 'flex', justifyContent: 'center' }}
        >
          <img
            src={theme.palette.mode === 'light' ? '/static/BankID-main-logo-purple.svg' : '/static/BankID-main-logo-white.svg'}
            height="30"
            width="auto"
            alt="BankID"
          />
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ textAlign: 'center' }}
        >
          <Button
            variant="contained"
            color="info"
            sx={{ ml: 'auto', width: '200px' }}
            size="large"
            onClick={login}
          >
            {t('Sign in')}
          </Button>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: 1 }}
        >
          <Typography
            color={theme.palette.grey[600]}
            variant="body2"
          >
            <Trans>
              By logging in, you accept and confirm Zensum
              <Link
                href="https://www.zensum.no/brukervilkar"
                target="_blank"
              >
                terms of use
              </Link>
              and
              <Link
                href="https://www.zensum.no/personvern"
                target="_blank"
              >
                privacy policy
              </Link>
              .
            </Trans>
          </Typography>
        </Grid>
      </Grid>
    </Card>
  );
};

export default LoginBox;
