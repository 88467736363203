import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Container, IconButton, Link, Toolbar } from '@material-ui/core';
import { Logout } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

import { DashboardNavbarRoot } from 'src/components/dashboard/DashboardNavbar';
import LanguagePopover from 'src/components/dashboard/LanguagePopover';
import MenuIcon from 'src/icons/Menu';

interface CustomerPagesNavbarProps {
  openSidebar: () => void;
}

const CustomerPagesNavbar: FC<CustomerPagesNavbarProps> = (props) => {
  const { openSidebar } = props;

  const { t } = useTranslation();

  return (
    <DashboardNavbarRoot position="static">
      <Container
        maxWidth="lg"
        sx={{
          px: { xs: 1 },
        }}
      >
        <Toolbar
          sx={{
            minHeight: 64,
            flexDirection: 'row',
            px: { xs: 0 },
          }}
        >
          <IconButton
            color="inherit"
            onClick={openSidebar}
            sx={{ px: { xs: 1, lg: 0 } }}
          >
            <MenuIcon fontSize="small" />
          </IconButton>
          <Box sx={{ flexGrow: 1 }} />
          <LanguagePopover />
          <Link
            component={RouterLink}
            to="/logout"
            sx={{
              ml: 1,
              color: 'inherit',
              fontWeight: '500',
              display: 'inline-flex',
              alignItems: 'center',
              gap: 0.5,
            }}
          >
            {t('Logout')}
            <Logout fontSize="inherit" />
          </Link>
        </Toolbar>
      </Container>
    </DashboardNavbarRoot>
  );
};

export default CustomerPagesNavbar;
