/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface EventOfInsurance {
  data?: object;

  /**
   * @format date-time
   * @example Mon, 02 Mar 2020 17:43:26 GMT
   */
  date?: string;
  event?:
    | "INSURANCE_CREATED"
    | "INSURANCE_UPDATED"
    | "INSURANCE_CANCELLED"
    | "CUSTOMER_ACCEPTED_TERMS"
    | "TRUSTLY_INITIATED"
    | "TRUSTLY_ACCOUNT_INFO"
    | "TRUSTLY_MANDATE_ACQUIRED"
    | "TRUSTLY_MANDATE_CANCELLED"
    | "TRUSTLY_MANDATE_SIGNUP_CANCELLED"
    | "TRUSTLY_UNKNOWN_EVENT"
    | "TRUSTLY_CHARGE_INITIATED"
    | "TRUSTLY_PENDING_CHARGE"
    | "TRUSTLY_ACCOUNT_CHARGED"
    | "TRUSTLY_CHARGE_CANCELLED"
    | "TRUSTLY_CANCEL_ORDER_INITIATED"
    | "TRUSTLY_CANCEL_ORDER_FAILED"
    | "INVITATION_EMAIL_SENT"
    | "INVITATION_EMAIL_SENT_AGAIN"
    | "INVITATION_EMAIL_REMINDER_SENT"
    | "SIGNUP_CONFIRMATION_EMAIL_SENT"
    | "SIGNUP_CONFIRMATION_EMAIL_SENT_AGAIN"
    | "FAILED_TO_CHARGE_EMAIL_SENT"
    | "FAILED_TO_CHARGE_EMAIL_SENT_AGAIN"
    | "MANDATE_CANCELLED_EMAIL_SENT"
    | "MANDATE_CANCELLED_EMAIL_SENT_AGAIN"
    | "NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT"
    | "NEW_MANDATE_ACCOUNT_INFO_RECEIVED_EMAIL_SENT_AGAIN"
    | "CANCEL_EMAIL_UNPAID_PREMIUM_SENT"
    | "CANCEL_EMAIL_UNPAID_PREMIUM_SENT_AGAIN"
    | "CANCEL_EMAIL_CUSTOMER_AGE_SENT"
    | "CANCEL_EMAIL_CUSTOMER_AGE_SENT_AGAIN"
    | "CANCEL_EMAIL_MANDATE_CANCELLED_SENT"
    | "CANCEL_EMAIL_MANDATE_CANCELLED_SENT_AGAIN"
    | "CANCEL_EMAIL_GENERIC_SENT"
    | "CANCEL_EMAIL_GENERIC_SENT_AGAIN"
    | "NEW_MANDATE_ACQUIRED_EMAIL_SENT"
    | "NEW_MANDATE_ACQUIRED_EMAIL_SENT_AGAIN"
    | "REQUEST_NEW_MANDATE_EMAIL_SENT"
    | "CUSTOMER_REQUESTED_SIGNUP_DATA";

  /** @example 5d07e7de-b08a-4806-8426-b4fb8297681f */
  eventId?: string;

  /** @example MAIN */
  type?: "EMAIL" | "CHARGE" | "MAIN" | "MANDATE" | "OTHER" | "TRACKING";

  /** @example auth0|5d07e7de-b08a-4806-8426-b4fb8297681f */
  userId?: string;
}

export interface FullCustomer {
  /** @example Ankeborg */
  city?: string;

  /** @example Sweden */
  country?: string;

  /** @example ce16ab96-270f-45be-8fe3-8a4e6d81b06e */
  customerId?: string;

  /** @example example@zensum.se */
  email?: string;

  /** @example Pelle */
  givenName?: string;

  /** @example Karlsson */
  lastName?: string;

  /** @example 46761234567 */
  phoneNumber?: string;

  /** @example 111 21 */
  postalCode?: string;

  /** @example 198112189876 */
  ssn?: string;

  /** @example Storgatan 1 */
  streetAddress?: string;
}

export interface Insurance {
  /** @example SEK */
  currency?: "SEK";
  customer?: { customerId?: string; dateOfBirth?: string; givenName?: string };

  /** @example ec47c1ca-ff02-435e-ab64-8da154a64706 */
  customerId?: string;

  /** @format date-time */
  dateCreated?: string;
  events?: EventOfInsurance[];

  /**
   * Number of failed monthly payments since last successful payment.
   * @example 0
   */
  failedConsecutiveMonthlyPayments?: number;

  /** @example Z9000001 */
  insuranceId?: string;

  /** @example AXA_ZS_SE */
  insuranceType?: "AXA_ZS_SE";

  /**
   * Insured amount
   * @example 2000
   */
  insuredAmount?: number;
  lastCharge?: { amount?: number; date?: string };

  /** @format date-time */
  lastUpdated?: string;
  mandate?: {
    accountId?: number;
    acquired?: boolean;
    bank?: string;
    canceled?: boolean;
    date?: string;
    lastBankAccountDigits?: number;
    waiting?: boolean;
  };

  /**
   * The premium / cost of the insurance
   * @example 119
   */
  premiumAmount?: number;

  /** @example auth0|5e1dc3db8b17ee0e75d4f9d9 */
  sellerId?: string;

  /** @example Pelle Jönsson */
  sellerName?: string;

  /** @format date-time */
  signupDate?: string;
  status?: "ACTIVE" | "INACTIVE";
  substatus?:
    | "WAITING_FOR_CUSTOMER_ACCEPTANCE"
    | "INACTIVE_MANDATE"
    | "WAITING_FOR_BANK"
    | "ACTIVE"
    | "CANCELED"
    | "DISCARDED";

  /** @example 118 */
  totalCharged?: number;
}

export interface InsuranceSummary {
  activation_total?: {
    insurance_type?: string;
    lower_count?: number;
    lower_insured_amount?: number;
    lower_premium_amount?: number;
    seller?: string;
    seller_id?: string;
    total_count?: number;
    total_insured_amount?: number;
    total_premium_amount?: number;
    upper_count?: number;
    upper_insured_amount?: number;
    upper_premium_amount?: number;
  }[];
  activations?: {
    activation_date?: string;
    insurance_id?: string;
    insurance_type?: string;
    insured_amount?: number;
    premium_amount?: number;
    seller?: string;
    seller_id?: string;
  }[];
  cancelation_total?: {
    insurance_type?: string;
    lower_count?: number;
    lower_insured_amount?: number;
    lower_premium_amount?: number;
    seller?: string;
    seller_id?: string;
    total_count?: number;
    total_insured_amount?: number;
    total_premium_amount?: number;
    upper_count?: number;
    upper_insured_amount?: number;
    upper_premium_amount?: number;
  }[];
  cancelations?: {
    cancelation_date?: string;
    insurance_id?: string;
    insurance_type?: string;
    insured_amount?: number;
    premium_amount?: number;
    seller?: string;
    seller_id?: string;
  }[];
}

export interface InsuranceWithoutCustomer {
  /** @example SEK */
  currency?: "SEK";

  /** @example ec47c1ca-ff02-435e-ab64-8da154a64706 */
  customerId?: string;

  /** @format date-time */
  dateCreated?: string;
  events?: EventOfInsurance[];

  /**
   * Number of failed monthly payments since last successful payment.
   * @example 0
   */
  failedConsecutiveMonthlyPayments?: number;

  /** @example Z9000001 */
  insuranceId?: string;

  /** @example AXA_ZS_SE */
  insuranceType?: "AXA_ZS_SE";

  /**
   * Insured amount
   * @example 2000
   */
  insuredAmount?: number;
  lastCharge?: { amount?: number; date?: string };

  /** @format date-time */
  lastUpdated?: string;
  mandate?: {
    accountId?: number;
    acquired?: boolean;
    bank?: string;
    canceled?: boolean;
    date?: string;
    lastBankAccountDigits?: number;
    waiting?: boolean;
  };

  /**
   * The premium / cost of the insurance
   * @example 119
   */
  premiumAmount?: number;

  /** @example auth0|5e1dc3db8b17ee0e75d4f9d9 */
  sellerId?: string;

  /** @example Pelle Jönsson */
  sellerName?: string;

  /** @format date-time */
  signupDate?: string;
  status?: "ACTIVE" | "INACTIVE";
  substatus?:
    | "WAITING_FOR_CUSTOMER_ACCEPTANCE"
    | "INACTIVE_MANDATE"
    | "WAITING_FOR_BANK"
    | "ACTIVE"
    | "CANCELED"
    | "DISCARDED";

  /** @example 118 */
  totalCharged?: number;
}

export namespace Admin {
  /**
   * No description
   * @name DescriptionAdmin
   * @request DESCRIPTION:/admin/sales-report
   */
  export namespace DescriptionAdmin {
    export type RequestParams = {};
    export type RequestQuery = { fromDate: string; toDate: string; format?: "JSON" | "CSV" };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name ApiGetSellerSalesReport
   * @request GET:/admin/sales-report
   */
  export namespace ApiGetSellerSalesReport {
    export type RequestParams = {};
    export type RequestQuery = { fromDate: string; toDate: string; format?: "JSON" | "CSV" };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
  /**
   * @description Cancels Vipps mandate in case there is something wrong with the automatic cancel.
   * @name ApiCancelVippsMandate
   * @summary Cancel Vipps mandate
   * @request PUT:/admin/vipps/cancel/mandate/{insurance_id}/{agreement_id}
   */
  export namespace ApiCancelVippsMandate {
    export type RequestParams = { insuranceId: string; agreementId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
  /**
   * @description Created for debugging purposes since the Vipps requires Norweign bank id for admin portal
   * @name ApiGetLastChargeOriginalVippsValue
   * @summary Does a lookup in the Vipps system
   * @request GET:/admin/vipps/last-charge/{insurance_id}
   */
  export namespace ApiGetLastChargeOriginalVippsValue {
    export type RequestParams = { insuranceId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace CanSignup {
  /**
   * @description Check if the customer can signup for an insurance
   * @name ApiCanHaveInsurance
   * @summary Check if the customer can signup for an insurance
   * @request POST:/can-signup
   */
  export namespace ApiCanHaveInsurance {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { ssn: string[] };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace CancelInsurance {
  /**
   * @description Cancel an active or pending insurance. This operation is idempotent, repeating the request several times should not have any effect beyond the initial request.
   * @name ApiCancelInsurance
   * @summary Cancel an insurance
   * @request PUT:/cancel-insurance
   */
  export namespace ApiCancelInsurance {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      cause:
        | "NO_LONGER_QUALIFIED"
        | "SIGNED_UP_FOR_ANOTHER_INSURANCE"
        | "INSURANCE_TO_EXPENSIVE"
        | "DID_NOT_TAKE_THE_LOAN"
        | "NO_MORE_LOANS"
        | "OTHER";
      insuranceId: string;
    };
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
}

export namespace CancelInsuranceCauses {
  /**
   * @description Causes for cancelling an insurance
   * @name ApiGetCancelInsuranceCauses
   * @summary Cancel insurance causes
   * @request GET:/cancel-insurance-causes
   */
  export namespace ApiGetCancelInsuranceCauses {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }
}

export namespace CreateInsurance {
  /**
   * @description Request to service to create an insurance. A check will be done to verify that the customer is eligible to acquire an insurance, and that the requested amount is within the allowed bounds.
   * @name ApiCreateInsurance
   * @summary Request to create an insurance
   * @request POST:/create-insurance
   */
  export namespace ApiCreateInsurance {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      email: string;
      insuranceType: string;
      insuredAmount: number;
      phoneNumber: string;
      premium: number;
      ssn: string;
    };
    export type RequestHeaders = {};
    export type ResponseBody = { encryptedInsuranceId?: string; insuranceId?: string };
  }
}

export namespace Customer {
  /**
   * @description Request to service to update a customer.
   * @name ApiUpdateCustomer
   * @summary Update customer
   * @request PUT:/customer
   */
  export namespace ApiUpdateCustomer {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { customerId: string; email?: string; phoneNumber?: string };
    export type RequestHeaders = {};
    export type ResponseBody = FullCustomer;
  }
  /**
   * @description Retrieve information for customer with given ID. This information should only be requested when explicitly needed.
   * @name ApiGetCustomerInformation
   * @summary Retrieve customer information
   * @request GET:/customer/{customer_id}
   */
  export namespace ApiGetCustomerInformation {
    export type RequestParams = { customerId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = FullCustomer;
  }
}

export namespace CustomerBehalf {
  /**
   * @description Get all the active insurances a customer has
   * @name ApiHasActiveInsuranceOnCustomerBehalf
   * @summary Retrieves the active insurances a customer has.
   * @request GET:/customer-behalf/active-insurance
   */
  export namespace ApiHasActiveInsuranceOnCustomerBehalf {
    export type RequestParams = {};
    export type RequestQuery = { ssn: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance[];
  }
  /**
   * @description Request to service to create an insurance. A check will be done to verify that the customer is eligible to acquire an insurance, and that the requested amount is within the allowed bounds.
   * @name ApiCreateOnCustomerBehalf
   * @summary Request to create an insurance
   * @request POST:/customer-behalf/create
   */
  export namespace ApiCreateOnCustomerBehalf {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = {
      email: string;
      insuranceType: string;
      insuredAmount: number;
      phoneNumber: string;
      premium: number;
      ssn: string;
    };
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
  /**
   * @description Get the current "rules" that applies for an insurance that can be used to check if a customer is eligible to sign up for an insurance, such as minimum and maximum age and for which amounts the insurance may cover.
   * @name ApiRulesOnCustomerBehalf
   * @summary Retrieve business rules for insurances available for customers to sign up for.
   * @request GET:/customer-behalf/rules
   */
  export namespace ApiRulesOnCustomerBehalf {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = {
      countriesOfResidence?: string[];
      currency?: "SEK";
      employmentTypes?: "FULL_TIME"[];
      maxAge?: number;
      maxAmount?: number;
      maxInsuredRateVsMonthlyGrossIncome?: number;
      minAge?: number;
      minAmount?: number;
      premiumRate?: number;
    };
  }
}

export namespace CustomersInsurances {
  /**
   * @description Check if the customers have insurances
   * @name ApiGetActiveInsurancesForCustomerIds
   * @summary Get insurances for customer ids
   * @request POST:/customers_insurances
   */
  export namespace ApiGetActiveInsurancesForCustomerIds {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { customer_ids: string[] };
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceWithoutCustomer[];
  }
}

export namespace IdCheck {
  /**
   * @description Get ID-check from Kunddatalagringstjänsten, proxied.
   * @name ApiIdCheckFromKunddatalagringstjaensten
   * @request GET:/id-check
   */
  export namespace ApiIdCheckFromKunddatalagringstjaensten {
    export type RequestParams = {};
    export type RequestQuery = { ssn: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = { person?: { first_name?: string; last_name?: string; [key: string]: any } };
  }
}

export namespace InsuranceTypes {
  /**
   * @description Get the all insurance types
   * @name ApiGetInsuranceTypes
   * @summary Retrieve the insurance types
   * @request GET:/insurance-types
   */
  export namespace ApiGetInsuranceTypes {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string[];
  }
}

export namespace Insurance {
  /**
   * @description Get the current status for an insurance, together with payment history, when insurance was created and other relevant information.
   * @name ApiInsuranceStatusWithoutCustomerData
   * @summary Retrieve the current status and information about an existing insurance
   * @request GET:/insurance/slim/{insurance_id}
   */
  export namespace ApiInsuranceStatusWithoutCustomerData {
    export type RequestParams = { insuranceId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceWithoutCustomer;
  }
  /**
   * @description Get the current status for an insurance, together with payment history, when insurance was created and other relevant information.
   * @name ApiInsuranceStatus
   * @summary Retrieve the current status and information about an existing insurance
   * @request GET:/insurance/{insurance_id}
   */
  export namespace ApiInsuranceStatus {
    export type RequestParams = { insuranceId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
}

export namespace Insurances {
  /**
   * No description
   * @name DescriptionInsurances
   * @request DESCRIPTION:/insurances
   */
  export namespace DescriptionInsurances {
    export type RequestParams = {};
    export type RequestQuery = { q?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name ApiSearchForInsurances
   * @request GET:/insurances
   */
  export namespace ApiSearchForInsurances {
    export type RequestParams = {};
    export type RequestQuery = { q?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = { hits?: Insurance[]; paginated?: boolean; total?: number };
  }
}

export namespace MandateLink {
  /**
   * @description Get a new mandate link
   * @name ApiNewMandateLink
   * @summary Get a new mandate link
   * @request GET:/mandate-link
   */
  export namespace ApiNewMandateLink {
    export type RequestParams = {};
    export type RequestQuery = { insurance_id: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace MyInsurances {
  /**
   * @description Returns the insurances registered by the logged in user.
   * @name ApiGetMyInsurances
   * @request GET:/my-insurances
   */
  export namespace ApiGetMyInsurances {
    export type RequestParams = {};
    export type RequestQuery = { page: number; per_page: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Insurance[];
  }
  /**
   * @description Returns the insurances registered by the logged in user.
   * @name ApiGetMyInsurancesSlim
   * @request GET:/my-insurances/slim
   */
  export namespace ApiGetMyInsurancesSlim {
    export type RequestParams = {};
    export type RequestQuery = { page: number; per_page: number; substatus?: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceWithoutCustomer[];
  }
}

export namespace RefundLastCharge {
  /**
   * @description Allow the admin to refund the last charge
   * @name ApiRefundLastChargeForCanceledInsurance
   * @summary Refund last charge after the insurance is canceled
   * @request POST:/refund-last-charge
   */
  export namespace ApiRefundLastChargeForCanceledInsurance {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { description: string; insuranceId: string };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace RefundTrustly {
  /**
   * @description Allow the admin to refund the last charge
   * @name ApiRefundTrustlyCharge
   * @summary Refund Trustly charge
   * @request POST:/refund-trustly
   */
  export namespace ApiRefundTrustlyCharge {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { insuranceId: string };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Report {
  /**
   * No description
   * @name DescriptionReport
   * @request DESCRIPTION:/report/for-dw
   */
  export namespace DescriptionReport {
    export type RequestParams = {};
    export type RequestQuery = { fromDate: string; toDate: string; report: "PAYMENTS" | "INSURANCES" };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name ApiGetDwReport
   * @request GET:/report/for-dw
   */
  export namespace ApiGetDwReport {
    export type RequestParams = {};
    export type RequestQuery = { fromDate: string; toDate: string; report: "PAYMENTS" | "INSURANCES" };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = {
      data?: (
        | {
            amount?: number;
            chargeDate?: string;
            createdDate?: string | null;
            insuranceId?: string;
            paymentId?: string;
          }
        | {
            activationDate?: string;
            agentProductCode?: "9Z";
            cancellationCode?: string | null;
            cancellationDate?: string | null;
            employeeNumber?: number;
            insuranceId?: string;
            insuredAmount?: number;
            lastUpdated?: string;
            premium?: number;
            sellerId?: string;
            ssn?: string;
            status?: "ACTIVE" | "INACTIVE";
          }
      )[];
      from_date?: string;
      to_date?: string;
    };
  }
}

export namespace RequestNewMandateEmail {
  /**
   * @description Request an email to signup for a new mandate
   * @name ApiRequestNewMandateEmail
   * @summary Request an email to signup for a new mandate
   * @request POST:/request-new-mandate-email
   */
  export namespace ApiRequestNewMandateEmail {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { insuranceId: string };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace ResendEmail {
  /**
   * @description Request to resend an email for an insurance. E.g for the "invitation" email or for the terms.
   * @name ApiResendEmail
   * @summary Request to resend an email from event
   * @request POST:/resend-email
   */
  export namespace ApiResendEmail {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { eventId: string; insuranceId: string };
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
}

export namespace ResendInvitationEmail {
  /**
   * @description Request to resend an invitation email for an insurance.
   * @name ApiResendInvitationEmail
   * @summary Request to resend insurance invitation
   * @request POST:/resend-invitation-email
   */
  export namespace ApiResendInvitationEmail {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { insuranceId: string };
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
}

export namespace Rules {
  /**
   * @description Get the current "rules" that applies for an insurance that can be used to check if a customer is eligible to sign up for an insurance, such as minimum and maximum age and for which amounts the insurance may cover.
   * @name ApiRules
   * @summary Retrieve business rules for pre-verification
   * @request GET:/rules
   */
  export namespace ApiRules {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = {
      countriesOfResidence?: string[];
      country?: "SE" | "false";
      currency?: "SEK";
      employmentTypes?: "FULL_TIME"[];
      maxAge?: number;
      maxAmount?: number;
      maxInsuredRateVsMonthlyGrossIncome?: number;
      minAge?: number;
      minAmount?: number;
      payment_provider?: string;
      premiumRate?: number;
    };
  }
}

export namespace Seller {
  /**
   * No description
   * @name DescriptionSeller
   * @request DESCRIPTION:/seller/sales-report
   */
  export namespace DescriptionSeller {
    export type RequestParams = {};
    export type RequestQuery = { fromDate: string; toDate: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name ApiGetMySalesReport
   * @request GET:/seller/sales-report
   */
  export namespace ApiGetMySalesReport {
    export type RequestParams = {};
    export type RequestQuery = { fromDate: string; toDate: string };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = string;
  }
}

export namespace Statuses {
  /**
   * @description List of statuses and substatuses
   * @name ApiGetInsuranceStatusesAndSubstatuses
   * @summary Retrieve list of possible insurance statuses and substatuses
   * @request GET:/statuses
   */
  export namespace ApiGetInsuranceStatusesAndSubstatuses {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = { statuses?: string[]; substatuses?: string[] };
  }
}

export namespace Summary {
  /**
   * No description
   * @name ApiSakerInsuranceStats
   * @request GET:/summary
   */
  export namespace ApiSakerInsuranceStats {
    export type RequestParams = {};
    export type RequestQuery = { start_date: string; end_date: string; insured_amount_cutoff: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = InsuranceSummary;
  }
}

export namespace UpdateInsurance {
  /**
   * @description Request to service to update an insurance. A check will be done to verify that the customer is eligible to change an insurance, and that the requested amount is within the allowed bounds.
   * @name ApiUpdateInsurance
   * @summary Request to update an insurance
   * @request PUT:/update-insurance
   */
  export namespace ApiUpdateInsurance {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { insuranceId: string; insuredAmount: number; premium: number };
    export type RequestHeaders = {};
    export type ResponseBody = Insurance;
  }
}

export namespace WinbackFeedback {
  /**
   * @description Post feedback about attempt to win back customer
   * @name ApiWinbackFeedback
   * @request POST:/winback_feedback
   */
  export namespace ApiWinbackFeedback {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = { comment?: string; insuranceId: string; response: boolean; winbacked?: boolean };
    export type RequestHeaders = {};
    export type ResponseBody = void;
  }
}

export namespace Winbackable {
  /**
   * No description
   * @name DescriptionWinbackable
   * @request DESCRIPTION:/winbackable
   */
  export namespace DescriptionWinbackable {
    export type RequestParams = {};
    export type RequestQuery = { page: number; limit?: number; include_handled?: boolean; country?: "SE" | "NO" };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = any;
  }
  /**
   * No description
   * @name ApiGetCtaInsurances
   * @request GET:/winbackable
   */
  export namespace ApiGetCtaInsurances {
    export type RequestParams = {};
    export type RequestQuery = { page: number; limit?: number; include_handled?: boolean; country?: "SE" | "NO" };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = object;
  }
}

export namespace ZakerRace {
  /**
   * @description Get the leaderboard for the Zäker race
   * @name ApiZakerRace
   * @request GET:/zaker-race
   */
  export namespace ApiZakerRace {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = object[];
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends globalThis.Response {
  data: D;
  error: E;
  requestId: string;
}

export interface HttpErrorResponse<E> extends globalThis.Response {
  error: E;
  requestId: string;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "/api";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  private generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    // We need to generate new RequestID for every request
    let requestId = requestParams.headers["X-Request-ID"];
    if (!requestId) {
      requestId = this.generateUUID();
      requestParams.headers["X-Request-ID"] = requestId;
    }

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;
      r.requestId = requestId;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data as HttpErrorResponse<E>;
      return data;
    });
  };
}

/**
 * @title Zensum Säker
 * @version 3.0.0
 * @baseUrl /api
 * @contact <dev@zensum.se>
 *
 * Service for creating and managing Zensum Säker insurances for customers
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  admin = {
    /**
     * No description
     *
     * @name DescriptionAdmin
     * @request DESCRIPTION:/admin/sales-report
     */
    descriptionAdmin: (
      query: { fromDate: string; toDate: string; format?: "JSON" | "CSV" },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/admin/sales-report`,
        method: "DESCRIPTION",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name ApiGetSellerSalesReport
     * @request GET:/admin/sales-report
     */
    apiGetSellerSalesReport: (
      query: { fromDate: string; toDate: string; format?: "JSON" | "CSV" },
      params: RequestParams = {},
    ) =>
      this.request<string, any>({
        path: `/admin/sales-report`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Cancels Vipps mandate in case there is something wrong with the automatic cancel.
     *
     * @name ApiCancelVippsMandate
     * @summary Cancel Vipps mandate
     * @request PUT:/admin/vipps/cancel/mandate/{insurance_id}/{agreement_id}
     */
    apiCancelVippsMandate: (insuranceId: string, agreementId: string, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/admin/vipps/cancel/mandate/${insuranceId}/${agreementId}`,
        method: "PUT",
        ...params,
      }),

    /**
     * @description Created for debugging purposes since the Vipps requires Norweign bank id for admin portal
     *
     * @name ApiGetLastChargeOriginalVippsValue
     * @summary Does a lookup in the Vipps system
     * @request GET:/admin/vipps/last-charge/{insurance_id}
     */
    apiGetLastChargeOriginalVippsValue: (insuranceId: string, params: RequestParams = {}) =>
      this.request<void, void>({
        path: `/admin/vipps/last-charge/${insuranceId}`,
        method: "GET",
        ...params,
      }),
  };
  canSignup = {
    /**
     * @description Check if the customer can signup for an insurance
     *
     * @name ApiCanHaveInsurance
     * @summary Check if the customer can signup for an insurance
     * @request POST:/can-signup
     */
    apiCanHaveInsurance: (data: { ssn: string[] }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/can-signup`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  cancelInsurance = {
    /**
     * @description Cancel an active or pending insurance. This operation is idempotent, repeating the request several times should not have any effect beyond the initial request.
     *
     * @name ApiCancelInsurance
     * @summary Cancel an insurance
     * @request PUT:/cancel-insurance
     */
    apiCancelInsurance: (
      data: {
        cause:
          | "NO_LONGER_QUALIFIED"
          | "SIGNED_UP_FOR_ANOTHER_INSURANCE"
          | "INSURANCE_TO_EXPENSIVE"
          | "DID_NOT_TAKE_THE_LOAN"
          | "NO_MORE_LOANS"
          | "OTHER";
        insuranceId: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Insurance, void>({
        path: `/cancel-insurance`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  cancelInsuranceCauses = {
    /**
     * @description Causes for cancelling an insurance
     *
     * @name ApiGetCancelInsuranceCauses
     * @summary Cancel insurance causes
     * @request GET:/cancel-insurance-causes
     */
    apiGetCancelInsuranceCauses: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/cancel-insurance-causes`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  createInsurance = {
    /**
     * @description Request to service to create an insurance. A check will be done to verify that the customer is eligible to acquire an insurance, and that the requested amount is within the allowed bounds.
     *
     * @name ApiCreateInsurance
     * @summary Request to create an insurance
     * @request POST:/create-insurance
     */
    apiCreateInsurance: (
      data: {
        email: string;
        insuranceType: string;
        insuredAmount: number;
        phoneNumber: string;
        premium: number;
        ssn: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<
        { encryptedInsuranceId?: string; insuranceId?: string },
        { error?: string; insuranceId?: string } | { error?: string }
      >({
        path: `/create-insurance`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  customer = {
    /**
     * @description Request to service to update a customer.
     *
     * @name ApiUpdateCustomer
     * @summary Update customer
     * @request PUT:/customer
     */
    apiUpdateCustomer: (
      data: { customerId: string; email?: string; phoneNumber?: string },
      params: RequestParams = {},
    ) =>
      this.request<FullCustomer, any>({
        path: `/customer`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Retrieve information for customer with given ID. This information should only be requested when explicitly needed.
     *
     * @name ApiGetCustomerInformation
     * @summary Retrieve customer information
     * @request GET:/customer/{customer_id}
     */
    apiGetCustomerInformation: (customerId: string, params: RequestParams = {}) =>
      this.request<FullCustomer, any>({
        path: `/customer/${customerId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  customerBehalf = {
    /**
     * @description Get all the active insurances a customer has
     *
     * @name ApiHasActiveInsuranceOnCustomerBehalf
     * @summary Retrieves the active insurances a customer has.
     * @request GET:/customer-behalf/active-insurance
     */
    apiHasActiveInsuranceOnCustomerBehalf: (query: { ssn: string }, params: RequestParams = {}) =>
      this.request<Insurance[], any>({
        path: `/customer-behalf/active-insurance`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Request to service to create an insurance. A check will be done to verify that the customer is eligible to acquire an insurance, and that the requested amount is within the allowed bounds.
     *
     * @name ApiCreateOnCustomerBehalf
     * @summary Request to create an insurance
     * @request POST:/customer-behalf/create
     */
    apiCreateOnCustomerBehalf: (
      data: {
        email: string;
        insuranceType: string;
        insuredAmount: number;
        phoneNumber: string;
        premium: number;
        ssn: string;
      },
      params: RequestParams = {},
    ) =>
      this.request<Insurance, any>({
        path: `/customer-behalf/create`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * @description Get the current "rules" that applies for an insurance that can be used to check if a customer is eligible to sign up for an insurance, such as minimum and maximum age and for which amounts the insurance may cover.
     *
     * @name ApiRulesOnCustomerBehalf
     * @summary Retrieve business rules for insurances available for customers to sign up for.
     * @request GET:/customer-behalf/rules
     */
    apiRulesOnCustomerBehalf: (params: RequestParams = {}) =>
      this.request<
        {
          countriesOfResidence?: string[];
          currency?: "SEK";
          employmentTypes?: "FULL_TIME"[];
          maxAge?: number;
          maxAmount?: number;
          maxInsuredRateVsMonthlyGrossIncome?: number;
          minAge?: number;
          minAmount?: number;
          premiumRate?: number;
        },
        any
      >({
        path: `/customer-behalf/rules`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  customersInsurances = {
    /**
     * @description Check if the customers have insurances
     *
     * @name ApiGetActiveInsurancesForCustomerIds
     * @summary Get insurances for customer ids
     * @request POST:/customers_insurances
     */
    apiGetActiveInsurancesForCustomerIds: (data: { customer_ids: string[] }, params: RequestParams = {}) =>
      this.request<InsuranceWithoutCustomer[], any>({
        path: `/customers_insurances`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  idCheck = {
    /**
     * @description Get ID-check from Kunddatalagringstjänsten, proxied.
     *
     * @name ApiIdCheckFromKunddatalagringstjaensten
     * @request GET:/id-check
     */
    apiIdCheckFromKunddatalagringstjaensten: (query: { ssn: string }, params: RequestParams = {}) =>
      this.request<{ person?: { first_name?: string; last_name?: string; [key: string]: any } }, any>({
        path: `/id-check`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  insuranceTypes = {
    /**
     * @description Get the all insurance types
     *
     * @name ApiGetInsuranceTypes
     * @summary Retrieve the insurance types
     * @request GET:/insurance-types
     */
    apiGetInsuranceTypes: (params: RequestParams = {}) =>
      this.request<string[], any>({
        path: `/insurance-types`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  insurance = {
    /**
     * @description Get the current status for an insurance, together with payment history, when insurance was created and other relevant information.
     *
     * @name ApiInsuranceStatusWithoutCustomerData
     * @summary Retrieve the current status and information about an existing insurance
     * @request GET:/insurance/slim/{insurance_id}
     */
    apiInsuranceStatusWithoutCustomerData: (insuranceId: string, params: RequestParams = {}) =>
      this.request<InsuranceWithoutCustomer, void>({
        path: `/insurance/slim/${insuranceId}`,
        method: "GET",
        format: "json",
        ...params,
      }),

    /**
     * @description Get the current status for an insurance, together with payment history, when insurance was created and other relevant information.
     *
     * @name ApiInsuranceStatus
     * @summary Retrieve the current status and information about an existing insurance
     * @request GET:/insurance/{insurance_id}
     */
    apiInsuranceStatus: (insuranceId: string, params: RequestParams = {}) =>
      this.request<Insurance, void>({
        path: `/insurance/${insuranceId}`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  insurances = {
    /**
     * No description
     *
     * @name DescriptionInsurances
     * @request DESCRIPTION:/insurances
     */
    descriptionInsurances: (query?: { q?: string }, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/insurances`,
        method: "DESCRIPTION",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name ApiSearchForInsurances
     * @request GET:/insurances
     */
    apiSearchForInsurances: (query?: { q?: string }, params: RequestParams = {}) =>
      this.request<{ hits?: Insurance[]; paginated?: boolean; total?: number }, any>({
        path: `/insurances`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  mandateLink = {
    /**
     * @description Get a new mandate link
     *
     * @name ApiNewMandateLink
     * @summary Get a new mandate link
     * @request GET:/mandate-link
     */
    apiNewMandateLink: (query: { insurance_id: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/mandate-link`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  myInsurances = {
    /**
     * @description Returns the insurances registered by the logged in user.
     *
     * @name ApiGetMyInsurances
     * @request GET:/my-insurances
     */
    apiGetMyInsurances: (query: { page: number; per_page: number }, params: RequestParams = {}) =>
      this.request<Insurance[], any>({
        path: `/my-insurances`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),

    /**
     * @description Returns the insurances registered by the logged in user.
     *
     * @name ApiGetMyInsurancesSlim
     * @request GET:/my-insurances/slim
     */
    apiGetMyInsurancesSlim: (
      query: { page: number; per_page: number; substatus?: string },
      params: RequestParams = {},
    ) =>
      this.request<InsuranceWithoutCustomer[], any>({
        path: `/my-insurances/slim`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  refundLastCharge = {
    /**
     * @description Allow the admin to refund the last charge
     *
     * @name ApiRefundLastChargeForCanceledInsurance
     * @summary Refund last charge after the insurance is canceled
     * @request POST:/refund-last-charge
     */
    apiRefundLastChargeForCanceledInsurance: (
      data: { description: string; insuranceId: string },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/refund-last-charge`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  refundTrustly = {
    /**
     * @description Allow the admin to refund the last charge
     *
     * @name ApiRefundTrustlyCharge
     * @summary Refund Trustly charge
     * @request POST:/refund-trustly
     */
    apiRefundTrustlyCharge: (data: { insuranceId: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/refund-trustly`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  };
  report = {
    /**
     * No description
     *
     * @name DescriptionReport
     * @request DESCRIPTION:/report/for-dw
     */
    descriptionReport: (
      query: { fromDate: string; toDate: string; report: "PAYMENTS" | "INSURANCES" },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/report/for-dw`,
        method: "DESCRIPTION",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name ApiGetDwReport
     * @request GET:/report/for-dw
     */
    apiGetDwReport: (
      query: { fromDate: string; toDate: string; report: "PAYMENTS" | "INSURANCES" },
      params: RequestParams = {},
    ) =>
      this.request<
        {
          data?: (
            | {
                amount?: number;
                chargeDate?: string;
                createdDate?: string | null;
                insuranceId?: string;
                paymentId?: string;
              }
            | {
                activationDate?: string;
                agentProductCode?: "9Z";
                cancellationCode?: string | null;
                cancellationDate?: string | null;
                employeeNumber?: number;
                insuranceId?: string;
                insuredAmount?: number;
                lastUpdated?: string;
                premium?: number;
                sellerId?: string;
                ssn?: string;
                status?: "ACTIVE" | "INACTIVE";
              }
          )[];
          from_date?: string;
          to_date?: string;
        },
        any
      >({
        path: `/report/for-dw`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  requestNewMandateEmail = {
    /**
     * @description Request an email to signup for a new mandate
     *
     * @name ApiRequestNewMandateEmail
     * @summary Request an email to signup for a new mandate
     * @request POST:/request-new-mandate-email
     */
    apiRequestNewMandateEmail: (data: { insuranceId: string }, params: RequestParams = {}) =>
      this.request<void, any>({
        path: `/request-new-mandate-email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  resendEmail = {
    /**
     * @description Request to resend an email for an insurance. E.g for the "invitation" email or for the terms.
     *
     * @name ApiResendEmail
     * @summary Request to resend an email from event
     * @request POST:/resend-email
     */
    apiResendEmail: (data: { eventId: string; insuranceId: string }, params: RequestParams = {}) =>
      this.request<Insurance, any>({
        path: `/resend-email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  resendInvitationEmail = {
    /**
     * @description Request to resend an invitation email for an insurance.
     *
     * @name ApiResendInvitationEmail
     * @summary Request to resend insurance invitation
     * @request POST:/resend-invitation-email
     */
    apiResendInvitationEmail: (data: { insuranceId: string }, params: RequestParams = {}) =>
      this.request<Insurance, any>({
        path: `/resend-invitation-email`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  rules = {
    /**
     * @description Get the current "rules" that applies for an insurance that can be used to check if a customer is eligible to sign up for an insurance, such as minimum and maximum age and for which amounts the insurance may cover.
     *
     * @name ApiRules
     * @summary Retrieve business rules for pre-verification
     * @request GET:/rules
     */
    apiRules: (params: RequestParams = {}) =>
      this.request<
        {
          countriesOfResidence?: string[];
          country?: "SE" | "false";
          currency?: "SEK";
          employmentTypes?: "FULL_TIME"[];
          maxAge?: number;
          maxAmount?: number;
          maxInsuredRateVsMonthlyGrossIncome?: number;
          minAge?: number;
          minAmount?: number;
          payment_provider?: string;
          premiumRate?: number;
        },
        any
      >({
        path: `/rules`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  seller = {
    /**
     * No description
     *
     * @name DescriptionSeller
     * @request DESCRIPTION:/seller/sales-report
     */
    descriptionSeller: (query: { fromDate: string; toDate: string }, params: RequestParams = {}) =>
      this.request<any, any>({
        path: `/seller/sales-report`,
        method: "DESCRIPTION",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name ApiGetMySalesReport
     * @request GET:/seller/sales-report
     */
    apiGetMySalesReport: (query: { fromDate: string; toDate: string }, params: RequestParams = {}) =>
      this.request<string, any>({
        path: `/seller/sales-report`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  statuses = {
    /**
     * @description List of statuses and substatuses
     *
     * @name ApiGetInsuranceStatusesAndSubstatuses
     * @summary Retrieve list of possible insurance statuses and substatuses
     * @request GET:/statuses
     */
    apiGetInsuranceStatusesAndSubstatuses: (params: RequestParams = {}) =>
      this.request<{ statuses?: string[]; substatuses?: string[] }, any>({
        path: `/statuses`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
  summary = {
    /**
     * No description
     *
     * @name ApiSakerInsuranceStats
     * @request GET:/summary
     */
    apiSakerInsuranceStats: (
      query: { start_date: string; end_date: string; insured_amount_cutoff: number },
      params: RequestParams = {},
    ) =>
      this.request<InsuranceSummary, any>({
        path: `/summary`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  updateInsurance = {
    /**
     * @description Request to service to update an insurance. A check will be done to verify that the customer is eligible to change an insurance, and that the requested amount is within the allowed bounds.
     *
     * @name ApiUpdateInsurance
     * @summary Request to update an insurance
     * @request PUT:/update-insurance
     */
    apiUpdateInsurance: (
      data: { insuranceId: string; insuredAmount: number; premium: number },
      params: RequestParams = {},
    ) =>
      this.request<Insurance, any>({
        path: `/update-insurance`,
        method: "PUT",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  winbackFeedback = {
    /**
     * @description Post feedback about attempt to win back customer
     *
     * @name ApiWinbackFeedback
     * @request POST:/winback_feedback
     */
    apiWinbackFeedback: (
      data: { comment?: string; insuranceId: string; response: boolean; winbacked?: boolean },
      params: RequestParams = {},
    ) =>
      this.request<void, any>({
        path: `/winback_feedback`,
        method: "POST",
        body: data,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),
  };
  winbackable = {
    /**
     * No description
     *
     * @name DescriptionWinbackable
     * @request DESCRIPTION:/winbackable
     */
    descriptionWinbackable: (
      query: { page: number; limit?: number; include_handled?: boolean; country?: "SE" | "NO" },
      params: RequestParams = {},
    ) =>
      this.request<any, any>({
        path: `/winbackable`,
        method: "DESCRIPTION",
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @name ApiGetCtaInsurances
     * @request GET:/winbackable
     */
    apiGetCtaInsurances: (
      query: { page: number; limit?: number; include_handled?: boolean; country?: "SE" | "NO" },
      params: RequestParams = {},
    ) =>
      this.request<object, any>({
        path: `/winbackable`,
        method: "GET",
        query: query,
        format: "json",
        ...params,
      }),
  };
  zakerRace = {
    /**
     * @description Get the leaderboard for the Zäker race
     *
     * @name ApiZakerRace
     * @request GET:/zaker-race
     */
    apiZakerRace: (params: RequestParams = {}) =>
      this.request<object[], any>({
        path: `/zaker-race`,
        method: "GET",
        format: "json",
        ...params,
      }),
  };
}
