/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface Account {
  clearing: string;
  accNo: string;
}

export type AnyResp = object;

export interface Applicant {
  customer_id?: string;

  /** An enumeration. */
  country: Country;
  personalId: string;
  firstName: string;
  lastName: string;
  sverker_id?: number;
  email?: string;
  phonePrimary?: string;
  phoneHome?: string;
  phoneDay?: string;
  propertyType?: string;
  propertyCost?: number;
  maritalStatus?: string;
  children?: number;
  postalCareOf?: string;
  postalAddress?: string;
  postalCode?: string;
  postalCity?: string;
  citizen?: number;
  education?: string;
  partnerIncome?: number;
  employer?: Employer;
  incomes?: Income[];
  account?: Account;

  /** @format date */
  immigrated_at?: string;
  creditCheck?: CreditCheckSE | CreditCheckNO | (CreditCheckSE & CreditCheckNO);
  fullName?: string;
  address?: string;
  documents?: ApplicantDocument[];
  is_pep?: boolean;
  current_housing?: string;
  ev_estate?: EvEstate[];
  verified_credits?: boolean;

  /**
   * @min 0
   * @max 10
   */
  paying_child_support?: number;

  /**
   * @min 0
   * @max 10
   */
  receiving_child_support?: number;
  citizenship?: string[];
  housing_since?: string;
}

export interface ApplicantDocument {
  document_id: string;
  document_type: string;
}

/**
 * Contains all data related to application used for filtering and generating application created cloud event
 */
export interface ApplicationData {
  id: number;
  desiredLoan: number;
  newLoan?: number;
  loanPurpose?: string;
  desiredPaybackTime: number;
  biddedPaybackTime: number;
  sumLoan: number;
  applicant: Applicant;
  coApplicant?: Applicant;
  credits: Credit[];
  offers: Offer[];
  disbursed_loans: DisbursedLoan[];
  disbursed_banks: DisbursedBank[];
  banks_invite_after_disburse_months: Record<string, number>;
  creator_id: number;
  bank_names: Record<string, string>;
  is_deactivated?: boolean;
  partial_refinance?: boolean;
  refinance_amount?: number;
  refinance_percent?: number;
  remove_refinance?: boolean;
  remove_new_loan?: boolean;
  keep_invalid_employers_phone?: boolean;
  keep_five_digit_clearing_number?: boolean;
  current_bank_id?: number;

  /** @format date-time */
  invite_start?: string;

  /** @format date-time */
  created_at?: string;

  /** An enumeration. */
  country?: Country;

  /** @format date-time */
  return_at?: string;
}

export interface BodyUploadModelSmartFilterUploadModelPost {
  /** @format binary */
  file: File;
  bank_ids: number[] | string;

  /** An enumeration. */
  country: Country;

  /** An enumeration. */
  product: Product;
  version: string;
}

/**
 * An enumeration.
 */
export enum Country {
  SE = "SE",
  NO = "NO",
}

export interface Credit {
  creditType_id: number;
  creditType_name: string;
  sum: number;
  monthlyPayment: number;
  bank_id: number;
  liable?: string;
  rate: number;
  refinanceAmount?: number;
  monthly_amortisation?: number;

  /** An enumeration. */
  responsibility?: Responsibility;
}

export interface CreditCheckNO {
  earnedIncome: number;

  /** @format date-time */
  mostRecentNoteDate?: string;
  debt_equity_ratio?: number;
  debt_equtiy_year?: number;
  creditNotes?: number;
  expired?: boolean;
}

export interface CreditCheckSE {
  earnedIncome: number;

  /** @format date-time */
  mostRecentNoteDate?: string;
  riskAssessment?: number;
  creditNotes?: number;
  kfmDebt?: number;
  kfmDebtPublic?: number;
  kfmDebtPrivate?: number;
  numPrevious?: number;
  expired?: boolean;
  score?: number;
  inquiries?: number;
  issuers?: string[];
}

export interface DisbursedBank {
  bank_id: number;

  /** @format date-time */
  disbursed_date: string;
  amount: number;
}

export interface DisbursedLoan {
  bank_id: number;

  /** @format date-time */
  disbursed_date: string;
  amount: number;
  monthlyPayment: number;
  paybackTime: number;
  rate: number;
  apr: number;
}

export interface Employer {
  type: string;
  name?: string;

  /** @format date-time */
  start_date: string;
  retirement_age?: number;

  /** @format date-time */
  end_date?: string;
  phone?: string;
  organization_number?: string;
  profession?: string;
}

export interface EvEstate {
  municipality?: string;
  municipality_number?: number;
  cadastralUnitNumber?: number;
  unitNumber?: number;
  leaseholdUnitNumber?: number;
  sectionNumber?: number;
  apartmentReference?: string;
  organizationNumber?: string;
  estimatedValue?: number;
  commonDebt?: number;
  estateType?: string;
  address?: EvEstateAddress;
}

export interface EvEstateAddress {
  streetName?: string;
  streetNumber?: number;
  streetLetter?: string;
  postCodeArea?: string;
  postCode?: string;
}

/**
 * An enumeration.
 */
export enum Feature {
  ApplicantCreditCheckScore = "applicant.credit_check.score",
  ApplicantIncome = "applicant.income",
  ApplicantCreditsCarLoanSum = "applicant.credits.car_loan_sum",
  ApplicantCreditsMortgageLoanSum = "applicant.credits.mortgage_loan_sum",
  ApplicantCreditsStudentLoanSum = "applicant.credits.student_loan_sum",
  ApplicantCreditsUnsecuredSum = "applicant.credits.unsecured_sum",
  ApplicantCreditsOtherSum = "applicant.credits.other_sum",
  ApplicantCreditsCount = "applicant.credits.count",
  ApplicantDependentChildren = "applicant.dependentChildren",
  ApplicantHousingCost = "applicant.housingCost",
  ApplicantCreditCheckInquiries = "applicant.credit_check.inquiries",
  ApplicantCreditCheckHasPaymentRemarks = "applicant.credit_check.has_payment_remarks",
  ApplicantEmployerType = "applicant.employer.type",
  ApplicantPropertyType = "applicant.propertyType",
  ApplicantMaritalStatus = "applicant.maritalStatus",
}

export interface HTTPValidationError {
  detail?: ValidationError[];
}

export interface Income {
  /** An enumeration. */
  type: IncomeType;
  name: string;
  amount: number;
  currency: string;
}

/**
 * An enumeration.
 */
export enum IncomeType {
  Rent = "rent",
  Other = "other",
  Employment = "employment",
  Partner = "partner",
}

/**
 * An enumeration.
 */
export enum MLModelType {
  SMART_FILTER = "SMART_FILTER",
}

export interface Offer {
  id: string;
  bank_id: number;
  status_id: number;
  rate?: number;
  sum?: number;
  status_name: string;
  external_offer_id?: string;
  amortization_plan: string;
  sum_to_solve?: number;
  monthly_payment: number;
  admin_fee?: number;
  setup_fee?: number;
  apr?: number;

  /** @format date-time */
  created_at: string;
  need_more_info?: boolean;

  /** Payback time in months */
  payback_time: number;
  direct_debit_fee?: number;
  is_accepted?: boolean;
}

export interface PredictionScore {
  score: number;
  version: string;
}

/**
 * An enumeration.
 */
export enum Product {
  Blanco = "blanco",
  Mortgage = "mortgage",
}

/**
 * An enumeration.
 */
export enum Responsibility {
  MAIN_APPLICANT = "MAIN_APPLICANT",
  CO_APPLICANT = "CO_APPLICANT",
  SHARED = "SHARED",
  GUARANTOR = "GUARANTOR",
}

export interface SmartFilter {
  /** @format date-time */
  timestamp: string;
  version: string;
  file_id: string;

  /** An enumeration. */
  country: Country;

  /** An enumeration. */
  product: Product;

  /** An enumeration. */
  model_type: MLModelType;
  model_specific_identifier: SmartFilterIdentifier;
  user_id: string;
  content_type: string;
}

export interface SmartFilterIdentifier {
  bank_ids: number[];
}

export interface SmartFilterPayload {
  /** Contains all data related to application used for filtering and generating application created cloud event */
  app: ApplicationData;
  bank_id?: number;
  version?: string;
  file_id?: string;
}

export interface UploadResponse {
  model_id: string;
}

export interface ValidationError {
  loc: (string | number)[];
  msg: string;
  type: string;
}

export namespace SmartFilter {
  /**
   * No description
   * @tags Smart Filter
   * @name SmartFilterScoreSmartFilterScorePost
   * @summary Smart Filter Score
   * @request POST:/smart-filter/score
   * @secure
   */
  export namespace SmartFilterScoreSmartFilterScorePost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = SmartFilterPayload;
    export type RequestHeaders = {};
    export type ResponseBody = PredictionScore;
  }
  /**
   * No description
   * @tags Smart Filter
   * @name UploadModelSmartFilterUploadModelPost
   * @summary Upload Model
   * @request POST:/smart-filter/upload_model
   * @secure
   */
  export namespace UploadModelSmartFilterUploadModelPost {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = BodyUploadModelSmartFilterUploadModelPost;
    export type RequestHeaders = {};
    export type ResponseBody = UploadResponse;
  }
  /**
   * No description
   * @tags Smart Filter
   * @name AvailableModelsSmartFilterModelsGet
   * @summary Available Models
   * @request GET:/smart-filter/models
   * @secure
   */
  export namespace AvailableModelsSmartFilterModelsGet {
    export type RequestParams = {};
    export type RequestQuery = { page?: number; limit?: number };
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SmartFilter[];
  }
  /**
   * No description
   * @tags Smart Filter
   * @name GetModelSmartFilterModelsFileIdGet
   * @summary Get Model
   * @request GET:/smart-filter/models/{file_id}
   * @secure
   */
  export namespace GetModelSmartFilterModelsFileIdGet {
    export type RequestParams = { fileId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = SmartFilter;
  }
  /**
   * No description
   * @tags Smart Filter
   * @name AvailableFeaturesSmartFilterFeaturesFileIdGet
   * @summary Available Features
   * @request GET:/smart-filter/features/{file_id}
   * @secure
   */
  export namespace AvailableFeaturesSmartFilterFeaturesFileIdGet {
    export type RequestParams = { fileId: string };
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Record<string, string>;
  }
  /**
   * No description
   * @tags Smart Filter
   * @name FeatureCalcSmartFilterFeaturesFileIdPost
   * @summary Feature Calc
   * @request POST:/smart-filter/features/{file_id}
   * @secure
   */
  export namespace FeatureCalcSmartFilterFeaturesFileIdPost {
    export type RequestParams = { fileId: string };
    export type RequestQuery = {};
    export type RequestBody = ApplicationData;
    export type RequestHeaders = {};
    export type ResponseBody = AnyResp;
  }
  /**
   * No description
   * @tags Smart Filter
   * @name AllKnownFeaturesSmartFilterFeaturesGet
   * @summary All Known Features
   * @request GET:/smart-filter/features
   * @secure
   */
  export namespace AllKnownFeaturesSmartFilterFeaturesGet {
    export type RequestParams = {};
    export type RequestQuery = {};
    export type RequestBody = never;
    export type RequestHeaders = {};
    export type ResponseBody = Feature[];
  }
}

export type QueryParamsType = Record<string | number, any>;
export type ResponseFormat = keyof Omit<Body, "body" | "bodyUsed">;

export interface FullRequestParams extends Omit<RequestInit, "body"> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean;
  /** request path */
  path: string;
  /** content type of request body */
  type?: ContentType;
  /** query params */
  query?: QueryParamsType;
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseFormat;
  /** request body */
  body?: unknown;
  /** base url */
  baseUrl?: string;
  /** request cancellation token */
  cancelToken?: CancelToken;
}

export type RequestParams = Omit<FullRequestParams, "body" | "method" | "query" | "path">;

export interface ApiConfig<SecurityDataType = unknown> {
  baseUrl?: string;
  baseApiParams?: Omit<RequestParams, "baseUrl" | "cancelToken" | "signal">;
  securityWorker?: (securityData: SecurityDataType | null) => Promise<RequestParams | void> | RequestParams | void;
  customFetch?: typeof fetch;
}

export interface HttpResponse<D extends unknown, E extends unknown = unknown> extends globalThis.Response {
  data: D;
  error: E;
  requestId: string;
}

export interface HttpErrorResponse<E> extends globalThis.Response {
  error: E;
  requestId: string;
}

type CancelToken = Symbol | string | number;

export enum ContentType {
  Json = "application/json",
  FormData = "multipart/form-data",
  UrlEncoded = "application/x-www-form-urlencoded",
}

export class HttpClient<SecurityDataType = unknown> {
  public baseUrl: string = "";
  private securityData: SecurityDataType | null = null;
  private securityWorker?: ApiConfig<SecurityDataType>["securityWorker"];
  private abortControllers = new Map<CancelToken, AbortController>();
  private customFetch = (...fetchParams: Parameters<typeof fetch>) => fetch(...fetchParams);

  private baseApiParams: RequestParams = {
    credentials: "same-origin",
    headers: {},
    redirect: "follow",
    referrerPolicy: "no-referrer",
  };

  constructor(apiConfig: ApiConfig<SecurityDataType> = {}) {
    Object.assign(this, apiConfig);
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data;
  };

  private encodeQueryParam(key: string, value: any) {
    const encodedKey = encodeURIComponent(key);
    return `${encodedKey}=${encodeURIComponent(typeof value === "number" ? value : `${value}`)}`;
  }

  private addQueryParam(query: QueryParamsType, key: string) {
    return this.encodeQueryParam(key, query[key]);
  }

  private addArrayQueryParam(query: QueryParamsType, key: string) {
    const value = query[key];
    return value.map((v: any) => this.encodeQueryParam(key, v)).join("&");
  }

  protected toQueryString(rawQuery?: QueryParamsType): string {
    const query = rawQuery || {};
    const keys = Object.keys(query).filter((key) => "undefined" !== typeof query[key]);
    return keys
      .map((key) => (Array.isArray(query[key]) ? this.addArrayQueryParam(query, key) : this.addQueryParam(query, key)))
      .join("&");
  }

  protected addQueryParams(rawQuery?: QueryParamsType): string {
    const queryString = this.toQueryString(rawQuery);
    return queryString ? `?${queryString}` : "";
  }

  private contentFormatters: Record<ContentType, (input: any) => any> = {
    [ContentType.Json]: (input: any) =>
      input !== null && (typeof input === "object" || typeof input === "string") ? JSON.stringify(input) : input,
    [ContentType.FormData]: (input: any) =>
      Object.keys(input || {}).reduce((formData, key) => {
        const property = input[key];
        formData.append(
          key,
          property instanceof Blob
            ? property
            : typeof property === "object" && property !== null
            ? JSON.stringify(property)
            : `${property}`,
        );
        return formData;
      }, new FormData()),
    [ContentType.UrlEncoded]: (input: any) => this.toQueryString(input),
  };

  private mergeRequestParams(params1: RequestParams, params2?: RequestParams): RequestParams {
    return {
      ...this.baseApiParams,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...(this.baseApiParams.headers || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    };
  }

  private createAbortSignal = (cancelToken: CancelToken): AbortSignal | undefined => {
    if (this.abortControllers.has(cancelToken)) {
      const abortController = this.abortControllers.get(cancelToken);
      if (abortController) {
        return abortController.signal;
      }
      return void 0;
    }

    const abortController = new AbortController();
    this.abortControllers.set(cancelToken, abortController);
    return abortController.signal;
  };

  public abortRequest = (cancelToken: CancelToken) => {
    const abortController = this.abortControllers.get(cancelToken);

    if (abortController) {
      abortController.abort();
      this.abortControllers.delete(cancelToken);
    }
  };

  private generateUUID() {
    // Public Domain/MIT
    var d = new Date().getTime(); //Timestamp
    var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0; //Time in microseconds since page-load or 0 if unsupported
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
      var r = Math.random() * 16; //random number between 0 and 16
      if (d > 0) {
        //Use timestamp until depleted
        r = (d + r) % 16 | 0;
        d = Math.floor(d / 16);
      } else {
        //Use microseconds since page-load if supported
        r = (d2 + r) % 16 | 0;
        d2 = Math.floor(d2 / 16);
      }
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
  }

  public request = async <T = any, E = any>({
    body,
    secure,
    path,
    type,
    query,
    format,
    baseUrl,
    cancelToken,
    ...params
  }: FullRequestParams): Promise<HttpResponse<T, E>> => {
    const secureParams =
      ((typeof secure === "boolean" ? secure : this.baseApiParams.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {};
    const requestParams = this.mergeRequestParams(params, secureParams);
    const queryString = query && this.toQueryString(query);
    const payloadFormatter = this.contentFormatters[type || ContentType.Json];
    const responseFormat = format || requestParams.format;

    // We need to generate new RequestID for every request
    let requestId = requestParams.headers["X-Request-ID"];
    if (!requestId) {
      requestId = this.generateUUID();
      requestParams.headers["X-Request-ID"] = requestId;
    }

    return this.customFetch(`${baseUrl || this.baseUrl || ""}${path}${queryString ? `?${queryString}` : ""}`, {
      ...requestParams,
      headers: {
        ...(type && type !== ContentType.FormData ? { "Content-Type": type } : {}),
        ...(requestParams.headers || {}),
      },
      signal: cancelToken ? this.createAbortSignal(cancelToken) : void 0,
      body: typeof body === "undefined" || body === null ? null : payloadFormatter(body),
    }).then(async (response) => {
      const r = response as HttpResponse<T, E>;
      r.data = null as unknown as T;
      r.error = null as unknown as E;
      r.requestId = requestId;

      const data = !responseFormat
        ? r
        : await response[responseFormat]()
            .then((data) => {
              if (r.ok) {
                r.data = data;
              } else {
                r.error = data;
              }
              return r;
            })
            .catch((e) => {
              r.error = e;
              return r;
            });

      if (cancelToken) {
        this.abortControllers.delete(cancelToken);
      }

      if (!response.ok) throw data as HttpErrorResponse<E>;
      return data;
    });
  };
}

/**
 * @title ML-Engine
 * @version 0.1.0
 *
 *
 * Score a model on a dataset.
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  smartFilter = {
    /**
     * No description
     *
     * @tags Smart Filter
     * @name SmartFilterScoreSmartFilterScorePost
     * @summary Smart Filter Score
     * @request POST:/smart-filter/score
     * @secure
     */
    smartFilterScoreSmartFilterScorePost: (data: SmartFilterPayload, params: RequestParams = {}) =>
      this.request<PredictionScore, HTTPValidationError>({
        path: `/smart-filter/score`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name UploadModelSmartFilterUploadModelPost
     * @summary Upload Model
     * @request POST:/smart-filter/upload_model
     * @secure
     */
    uploadModelSmartFilterUploadModelPost: (
      data: BodyUploadModelSmartFilterUploadModelPost,
      params: RequestParams = {},
    ) =>
      this.request<UploadResponse, HTTPValidationError>({
        path: `/smart-filter/upload_model`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.UrlEncoded,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name AvailableModelsSmartFilterModelsGet
     * @summary Available Models
     * @request GET:/smart-filter/models
     * @secure
     */
    availableModelsSmartFilterModelsGet: (query?: { page?: number; limit?: number }, params: RequestParams = {}) =>
      this.request<SmartFilter[], HTTPValidationError>({
        path: `/smart-filter/models`,
        method: "GET",
        query: query,
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name GetModelSmartFilterModelsFileIdGet
     * @summary Get Model
     * @request GET:/smart-filter/models/{file_id}
     * @secure
     */
    getModelSmartFilterModelsFileIdGet: (fileId: string, params: RequestParams = {}) =>
      this.request<SmartFilter, HTTPValidationError>({
        path: `/smart-filter/models/${fileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name AvailableFeaturesSmartFilterFeaturesFileIdGet
     * @summary Available Features
     * @request GET:/smart-filter/features/{file_id}
     * @secure
     */
    availableFeaturesSmartFilterFeaturesFileIdGet: (fileId: string, params: RequestParams = {}) =>
      this.request<Record<string, string>, HTTPValidationError>({
        path: `/smart-filter/features/${fileId}`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name FeatureCalcSmartFilterFeaturesFileIdPost
     * @summary Feature Calc
     * @request POST:/smart-filter/features/{file_id}
     * @secure
     */
    featureCalcSmartFilterFeaturesFileIdPost: (fileId: string, data: ApplicationData, params: RequestParams = {}) =>
      this.request<AnyResp, HTTPValidationError>({
        path: `/smart-filter/features/${fileId}`,
        method: "POST",
        body: data,
        secure: true,
        type: ContentType.Json,
        format: "json",
        ...params,
      }),

    /**
     * No description
     *
     * @tags Smart Filter
     * @name AllKnownFeaturesSmartFilterFeaturesGet
     * @summary All Known Features
     * @request GET:/smart-filter/features
     * @secure
     */
    allKnownFeaturesSmartFilterFeaturesGet: (params: RequestParams = {}) =>
      this.request<Feature[], any>({
        path: `/smart-filter/features`,
        method: "GET",
        secure: true,
        format: "json",
        ...params,
      }),
  };
}
