import { FC, useState } from 'react';
import { Outlet } from 'react-router-dom';

import { Container, Grid } from '@material-ui/core';

import DashboardSidebar from 'src/components/dashboard/DashboardSidebar';
import CustomerPagesFooter from 'src/customerPages/layout/common/CustomerPagesFooter';
import CustomerPagesNavbar from 'src/customerPages/layout/common/CustomerPagesNavbar';
import useUser from 'src/hooks/useUser';
import { CustomerUser } from 'src/types/auth';

const CustomerPagesDashboard: FC = () => {
  const { impersonate } = useUser() as CustomerUser;

  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  return (
    <Grid
      container
      sx={{ px: 0, '*:focus-visible, .MuiInputBase-input:focus': { outline: '-webkit-focus-ring-color auto 1px' } }}
    >
      <Grid
        item
        xs={12}
      >
        <CustomerPagesNavbar openSidebar={() => setIsSidebarOpen(true)} />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <Container
          maxWidth="lg"
          sx={{ px: { xs: 1, md: 2, lg: 1 }, mb: 12 }}
        >
          <DashboardSidebar
            onMobileClose={(): void => setIsSidebarOpen(false)}
            openMobile={isSidebarOpen}
            mobileOnly
          />
          <Outlet />
        </Container>
      </Grid>
      {impersonate && (
        <Grid
          item
          xs={12}
        >
          <CustomerPagesFooter />
        </Grid>
      )}
    </Grid>
  );
};

export default CustomerPagesDashboard;
