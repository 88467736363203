import { FC, lazy, Suspense } from 'react';

import { Box, Fade, Typography } from '@material-ui/core';

import { getMessage } from 'src/utils/loadingMessages';

const Loadable = (Component) => (props) => (
  <Suspense fallback={null}>
    <Component {...props} />
  </Suspense>
);

const Logo = Loadable(lazy(() => import('./Logo')));

const SlashScreen: FC = () => (
  <Fade in>
    <Box
      sx={{
        alignItems: 'center',
        backgroundColor: 'background.paper',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        justifyContent: 'center',
        left: 0,
        position: 'fixed',
        top: 0,
        width: '100%',
        zIndex: 2000,
      }}
    >
      <Logo
        sx={{
          height: 200,
          width: 200,
        }}
      />
      <Typography>
        {getMessage()}
      </Typography>
    </Box>
  </Fade>
);

export default SlashScreen;
