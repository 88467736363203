import { createContext, FC, useContext, useMemo } from 'react';

import { v4 } from 'uuid';

import { Product } from 'src/api/zrm';
import useApi from 'src/hooks/useApi';
import useSettings from 'src/hooks/useSettings';
import logger from 'src/utils/logger';

export interface CustomerLoginContextProps {
  login: () => void;
}

const CustomerLoginContext = createContext<CustomerLoginContextProps>(null);

export const CustomerLoginProvider: FC = (props) => {
  const { children } = props;

  const { api } = useApi();
  const { country } = useSettings();

  const login = useMemo(() => async () => {
    const requestId = v4();
    const url = new URL(window.location.href);
    url.searchParams.delete('login-redirect');

    const failUrl = `${url.origin}/welcome-back`;

    try {
      logger.log({ country, callback_url: url.href, callback_fail_url: failUrl, product: Product.CreditCard, url }, { source: 'CreditCardLoginContext', description: 'Login with BankID data', requestId });

      const resp = await api.bankId.loginBankIdAuthPost(
        { country, callback_url: url.href, callback_fail_url: failUrl, product: Product.CreditCard },
        { headers: { Authorization: null, 'X-Request-ID': requestId }, cancelToken: requestId },
      );

      window.location.replace(resp.data.login_url);
    } catch (e) {
      if (e?.name === 'AbortError') return;

      logger.error(e, { source: 'CreditCardLoginContext', description: 'Login with BankID', requestId });
    }
  }, [api, country]);

  const value: CustomerLoginContextProps = useMemo(() => ({
    login,
  }), [login]);

  return (
    <CustomerLoginContext.Provider value={value}>
      {children}
    </CustomerLoginContext.Provider>
  );
};

export const useCustomerLogin = (): CustomerLoginContextProps => useContext(CustomerLoginContext);

export default CustomerLoginContext;
