import { createContext, Dispatch, FC, SetStateAction, useContext, useMemo, useState } from 'react';

import { Employee } from 'src/api/zrm';
import useFetchAllEmployees from 'src/hooks/api/useFetchAllEmployees';

export interface DialmakerDesktopCacheContextProps {
  pauseOnNext: boolean;
  setPauseOnNext: Dispatch<SetStateAction<boolean>>;
  outcomesBoxHeight: number;
  setOutcomesBoxHeight: Dispatch<SetStateAction<number>>;
  employees: Employee[];
}

const DialmakerDesktopCacheContext = createContext<DialmakerDesktopCacheContextProps>({
  pauseOnNext: false,
  setPauseOnNext: () => {},
  outcomesBoxHeight: 55,
  setOutcomesBoxHeight: () => {},
  employees: [],
});

export const DialmakerDesktopCacheProvider: FC = (props) => {
  const { children } = props;

  const { employees } = useFetchAllEmployees();

  const [pauseOnNext, setPauseOnNext] = useState(false);
  const [outcomesBoxHeight, setOutcomesBoxHeight] = useState(55);

  const value: DialmakerDesktopCacheContextProps = useMemo(() => ({
    pauseOnNext,
    setPauseOnNext,
    outcomesBoxHeight,
    setOutcomesBoxHeight,
    employees,
  }), [pauseOnNext, outcomesBoxHeight, employees]);

  return (
    <DialmakerDesktopCacheContext.Provider value={value}>
      {children}
    </DialmakerDesktopCacheContext.Provider>
  );
};

export const useDialmakerDesktopCache = (): DialmakerDesktopCacheContextProps => useContext(DialmakerDesktopCacheContext);

export default DialmakerDesktopCacheContext;
