export const messages = [
  'Sorry but we have written too much JavaScript',
  'Loading an obnoxious amount of JavaScript. Don\'t hold your breath!',
  'We are so sorry, but you\'re gonna have to wait here a little while',
  'Don\'t be in such a rush. We\'re doing what we can.',
  'There\'s more JavaScript incoming',
  'Be aware that this can take a while.',
  'Other activities you can do while waiting is for instance, the staring contest?',
  'So you want more? Hang on, it\'s coming!',
  'Sorry, but we need more JavaScript. We have written a lot of it. So please wait.',
  'Loading a ton of JavaScript. Do not hold your breath!',
  'Go get a ☕️, we\'ll have it ready when you\'re back!',
  'Stand up, shake your legs and sit back down again',
  'Getting bored? I bet you have some emails you can reply to while waiting!',
  'Too slow? Come help us fix the source code here https://github.com/zensum/zrm-frontend',
  'Sitt ner i båten, det kommer att ta en stund',
  'Please remain seated, we\'ll get this sorted out',
  'Sit down in the boat!',
];

export const getMessage = () => messages[Math.floor(Math.random() * messages.length)];
