import { Dispatch, FC, SetStateAction, useEffect, useMemo, useState } from 'react';

import {
  Box,
  Drawer,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import isEqual from 'fast-deep-equal';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DefaultCalendarView, ShareNameConsent } from 'src/api/zrm';
import { AuthenticationPlatform } from 'src/contexts/AuthContext';
import { AppUserSettings } from 'src/contexts/SettingsContext';
import useAuth from 'src/hooks/useAuth';
import beautifyEnumString from 'src/utils/beautifyEnumString';

import { THEMES } from '../constants';
import useSettings from '../hooks/useSettings';

interface SettingsDrawerProps {
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const themeNames = new Map([
  [THEMES.SYSTEM_THEME, 'System theme'],
  [THEMES.LIGHT, 'Light'],
  [THEMES.DARK, 'Dark'],
]);

const SettingsDrawer: FC<SettingsDrawerProps> = (props) => {
  const { open, setOpen } = props;

  const { settings, saveSettings } = useSettings();
  const { platform } = useAuth();
  const { t } = useTranslation();

  const [values, setValues] = useState<AppUserSettings>(settings);

  const isBank = useMemo(() => platform === AuthenticationPlatform.JWT, [platform]);

  const shareNameConsentOptions = useMemo(() => Object.values(ShareNameConsent).map((value) => ({
    value,
    label: beautifyEnumString(value),
  })), [beautifyEnumString]);

  const defaultCalendarViewOptions = useMemo(() => Object.values(DefaultCalendarView).map((value) => ({
    value,
    label: beautifyEnumString(value),
  })), [beautifyEnumString]);

  useEffect(() => {
    setValues(settings);
  }, [settings]);

  const handleChange = useMemo(() => (field: string, value: any): void => {
    setValues((v) => ({
      ...v,
      [field]: value,
    }));
  }, [setValues]);

  useEffect(() => {
    saveSettings((p) => isEqual(values, p) ? p : values);
  }, [values]);

  return (
    <>
      <Drawer
        anchor="right"
        onClose={() => setOpen(false)}
        open={open}
        PaperProps={{
          sx: {
            p: 2,
            width: 320,
          },
        }}
      >
        {!isBank && (
          <>
            <Typography
              color="textPrimary"
              variant="h6"
            >
              {t('User Settings')}
            </Typography>
            <Box marginY={3}>
              <TextField
                fullWidth
                label={t('Share name consent')}
                name="shareNameConsent"
                onChange={(event): void => handleChange(
                  'shareNameConsent',
                  event.target.value,
                )}
                select
                value={values.shareNameConsent}
                variant="outlined"
              >
                {shareNameConsentOptions.map((option) => (
                  <MenuItem
                    key={option.value}
                    value={option.value}
                  >
                    {t(option.label)}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </>
        )}
        <Typography
          color="textPrimary"
          variant="h6"
        >
          {t('System Settings')}
        </Typography>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label="Theme"
            name="theme"
            onChange={(event): void => handleChange(
              'userTheme',
              event.target.value,
            )}
            select
            SelectProps={{ native: true }}
            value={values.userTheme}
            variant="outlined"
          >
            {Object.keys(THEMES).map((theme) => (
              <option
                key={theme}
                value={theme}
              >
                {t(themeNames.get(theme))}
              </option>
            ))}
          </TextField>
        </Box>
        <Box sx={{ mt: 3 }}>
          <TextField
            fullWidth
            label={t('Default Calendar View')}
            name="defaultCalenderView"
            onChange={(event): void => handleChange(
              'default_calender_view',
              event.target.value,
            )}
            select
            value={values.default_calender_view}
            variant="outlined"
          >
            {defaultCalendarViewOptions.map((option) => (
              <MenuItem
                key={option.value}
                value={option.value}
              >
                {t(option.label)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.compact}
                color="primary"
                edge="start"
                name="compact"
                onChange={(event): void => handleChange(
                  'compact',
                  event.target.checked,
                )}
              />
            )}
            label={(
              <Box>
                {t('Compact')}
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  {t('Fixed width on some screens')}
                </Typography>
              </Box>
            )}
          />
        </Box>
        <Box
          sx={{
            mt: 2,
            px: 1.5,
          }}
        >
          <FormControlLabel
            control={(
              <Switch
                checked={values.debug}
                color="primary"
                edge="start"
                name="debug"
                onChange={(event): void => handleChange(
                  'debug',
                  event.target.checked,
                )}
              />
            )}
            label={(
              <Box>
                {t('Debug')}
                <Typography
                  color="textSecondary"
                  component="p"
                  variant="caption"
                >
                  {t('Show some debug stuff')}
                </Typography>
              </Box>
            )}
          />
        </Box>
      </Drawer>
    </>
  );
};

SettingsDrawer.propTypes = {
  open: PropTypes.bool,
  setOpen: PropTypes.func,
};

export default SettingsDrawer;
