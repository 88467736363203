import { createLogger, Logger } from '@logdna/logger';
import { v4 as uuid } from 'uuid';

type LogLevel = 'debug' | 'log' | 'warn' | 'error';
type LogMessage = string | { [key: string]: any; };

interface LogOptions {
  source?: string; // Component/hook/context name
  description?: string; // Small description about performed operation
  requestId?: string;
  [key: string]: any; // For values like ApplicationId etc.
}

const DEVICE_ID_KEY = 'deviceId';
const USER_ID_KEY = 'userId';

const messageToString = (message: LogMessage): string => {
  if (typeof message === 'string') return message;

  // Errors may not be iterable so we can't just JSON.Stringify them
  if (message instanceof Error) return message.toString();

  // Stringify all other objects
  return JSON.stringify(message);
};

/**
 * Custom logger class, which allows to log messages to LogDNA,
 * but only when `REACT_APP_LOGDNA_API_KEY` is provided.
 */
class CustomLogger {
  public deviceId: string;

  private logger: Logger;

  private loggerInitialized: boolean;

  constructor() {
    this.setDeviceId();

    try {
      this.logger = createLogger(process.env.REACT_APP_LOGDNA_API_KEY, {
        hostname: window.location.host.replace(/\.|:/g, '-'),
        app: 'zrm-frontend',
        indexMeta: true,
        sendUserAgent: false, // Sending User-Agent header causes error in browser
        meta: {
          deviceId: this.deviceId,
        },
      });
      this.loggerInitialized = true;

      console.log('Successfully initialized LogDNA logger instance');
    } catch (error) {
      this.loggerInitialized = false;

      console.groupCollapsed('LogDNA logger instance is not initialized. Don\'t worry if it is local/dev instance');
      console.error(error);
      console.groupEnd();
    }
  }

  private setDeviceId = () => {
    let deviceId = localStorage.getItem(DEVICE_ID_KEY);

    if (!deviceId) {
      deviceId = uuid();
      localStorage.setItem(DEVICE_ID_KEY, deviceId);
    }

    this.deviceId = deviceId;
  };

  private formatLogObject = (message: LogMessage): string => {
    if (typeof message === 'string') return message;

    return Object.keys(message)
      .filter((key) => !!message[key])
      .map((key) => `${key}=${messageToString(message[key])}`)
      .join(' | ');
  };

  private logFn = (message: LogMessage, logLevel: LogLevel, options: LogOptions) => {
    const { source, description, requestId, ...other } = options;

    console[logLevel](message, 'Log options:', { deviceId: this.deviceId, ...options });

    if (this.loggerInitialized) this.logger[logLevel](
      this.formatLogObject({ source, description, requestId, ...other, message }), // Destructuring in this way because of logs consistency
      { meta: { requestId } },
    );
  };

  setUserId = (userId: string) => {
    // eslint-disable-next-line curly
    if (this.logger) {
      if (userId) this.logger.addMetaProperty(USER_ID_KEY, userId);
      else this.logger.removeMetaProperty(USER_ID_KEY);
    }
  };

  debug = (message: LogMessage, options: LogOptions = {}) => {
    this.logFn(message, 'debug', options);
  };

  log = (message: LogMessage, options: LogOptions = {}) => {
    this.logFn(message, 'log', options);
  };

  error = (message: LogMessage, options: LogOptions = {}) => {
    // Check if message has 'error' key (ex. HttpError)
    const error = typeof message === 'string' ? message : message.error || message;

    this.logFn(error, 'error', options);
  };

  warn = (message: LogMessage, options: LogOptions = {}) => {
    this.logFn(message, 'warn', options);
  };
}

const logger = new CustomLogger();

export const { deviceId } = logger;

export default logger;
