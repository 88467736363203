import { FC } from 'react';

import { AppBar, Container, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import useUser from 'src/hooks/useUser';
import { CustomerUser } from 'src/types/auth';

const CustomerPagesFooter: FC = () => {
  const { t } = useTranslation();
  const user = useUser() as CustomerUser;

  return (
    <AppBar
      position="fixed"
      color="primary"
      sx={{ top: 'auto', bottom: 0, height: '80px' }}
    >
      <Container
        maxWidth="lg"
        sx={{
          p: 1,
        }}
      >
        <Typography>
          {t('Impersonating customer {{customerName}} ({{id}})', { customerName: `${user.first_name} ${user.last_name}`, id: user.user_id })}
        </Typography>
        <Typography fontSize="0.9rem">
          {t('Logged in user {{impersonatorName}} ({{id}})', { impersonatorName: user.impersonator_name, id: user.impersonator_id })}
        </Typography>
      </Container>
    </AppBar>
  );
};

export default CustomerPagesFooter;
