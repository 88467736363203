import 'moment/locale/en-gb';
import 'moment/locale/fi';
import 'moment/locale/nb';
import 'moment/locale/sv';

import type { FC, ReactNode } from 'react';
import { createContext, Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';

import useMediaQuery from '@material-ui/core/useMediaQuery';
import isEqual from 'fast-deep-equal';
import moment from 'moment';
import PropTypes from 'prop-types';
import { v4 as uuid } from 'uuid';

import { ShareNameConsent } from 'src/api/zrm';
import { Api, Country, DefaultCalendarView, Product, UserSettings } from 'src/api/zrm/zrmApi';
import useAuth from 'src/hooks/useAuth';
import i18n from 'src/i18n';
import { externalAuthPlatforms } from 'src/utils/auth/authPlatforms';
import { getLanguage } from 'src/utils/getLocale';
import { parseZoordinates, translateZoordinates } from 'src/utils/zoordinates';

import { ZRMApi } from './APIContext';
import { AuthenticationPlatform } from './AuthContext';
import { THEMES } from '../constants';

const localStorageZoordinatesKey = 'X-Zoordinates';

interface ExtendedSettings {
  product: Product;
  country: Country;
}

export interface SettingsContextValue extends ExtendedSettings {
  settings: AppUserSettings;
  saveSettings: Dispatch<SetStateAction<AppUserSettings>>;
  setBankCountryProduct?: Dispatch<SetStateAction<{ country: Country, product: Product }>>
}

const zKey = 'https://zensum.se/zoordinates';

interface SettingsProviderProps {
  children?: ReactNode;
}

export interface AppUserSettings extends UserSettings {
  userTheme: string,
}

const initialSettings: AppUserSettings = {
  compact: true,
  direction: 'ltr',
  zoordinates: null,
  debug: false,
  theme: window.matchMedia('(prefers-color-scheme: dark)').matches ? THEMES.DARK : THEMES.LIGHT,
  userTheme: THEMES.SYSTEM_THEME,
  shareNameConsent: ShareNameConsent.NO_CONSENT,
  default_calender_view: DefaultCalendarView.DayGridMonth,
};

const toUserSettings = (s: AppUserSettings): UserSettings => (({ userTheme, ...o }) => ({ ...o, theme: userTheme }))(s);
const toAppUserSettings = (s: UserSettings): AppUserSettings => s && ({ ...s, theme: '', userTheme: s.theme });

const SettingsContext = createContext<SettingsContextValue>({
  settings: initialSettings,
  saveSettings: () => { },
  country: null,
  product: null,
});

export const SettingsProvider: FC<SettingsProviderProps> = (props) => {
  const { children } = props;
  const { getAccessToken, getUser, platform, isAuthenticated } = useAuth();
  const [state, setState] = useState<{ settings: AppUserSettings, loaded: boolean }>({ settings: { ...initialSettings, zoordinates: localStorage.getItem(localStorageZoordinatesKey) || null }, loaded: false });
  const prefersDark = useMediaQuery('(prefers-color-scheme: dark)');

  const [bankCountryProduct, setBankCountryProduct] = useState<{ country: Country, product: Product }>({ country: Country.SE, product: Product.Mortgage });
  // TODO: some fancy logic to determine the country and product
  const [externalUserCountryProduct] = useState<{ country: Country, product: Product }>({ country: Country.NO, product: Product.Blanco });

  const isBank = useMemo(() => platform === AuthenticationPlatform.JWT, [platform]);
  const isExternalUser = useMemo(() => externalAuthPlatforms.includes(platform), [platform]);
  const isAuth0 = useMemo(() => platform === AuthenticationPlatform.Auth0, [platform]);

  useEffect(() => {
    if (state.settings?.zoordinates) localStorage.setItem(localStorageZoordinatesKey, state.settings.zoordinates);
  }, [state.settings?.zoordinates]);

  const api = useMemo((): ZRMApi => isAuth0 && new Api({
    baseUrl: process.env.REACT_APP_ZRM_URL,
    securityWorker: async () => Promise.all([getAccessToken(), getUser()])
      .then(([token, u]) => {
        let zoordinates = u?.[zKey]?.[0];

        if (u?.[zKey]?.includes(state?.settings?.zoordinates)) zoordinates = state.settings.zoordinates;

        return {
          headers: {
            Authorization: `Bearer ${token}`,
            'x-zoordinates': zoordinates,
          },
        };
      }),
  }), [state?.settings?.zoordinates, getAccessToken, getUser, isAuth0]);

  const handleUpdate = useMemo(() => (s: AppUserSettings | ((u: AppUserSettings) => AppUserSettings)) => {
    const result = typeof s === 'function' ? s(state.settings || {} as AppUserSettings) : s;
    const requestId = uuid();

    if (isEqual(result, state.settings)) return () => { };
    if (!isAuth0) setState((p) => ({ ...p, settings: result, loaded: true }));
    else if (result.zoordinates) {
      setState((p) => ({ ...p, settings: result, loaded: true }));

      if (state.loaded && api && isAuthenticated) api.employee.saveMySettingsEmployeeMySettingsPost(toUserSettings(result), { cancelToken: requestId, headers: { 'X-Request-ID': requestId } });
    }

    return () => { api.abortRequest(requestId); };
  }, [state, api, isAuth0]);

  useEffect(() => {
    if (!isAuth0 || state.loaded) return () => { };

    const requestId = uuid();
    (async () => {
      if (!api || !isAuthenticated) return;

      try {
        const { data } = await api.employee.getMySettingsEmployeeMySettingsGet({ cancelToken: requestId, headers: { 'X-Request-ID': requestId } });
        setState((previous) => ({ ...previous, settings: toAppUserSettings(data), loaded: true }));
      } catch (e) {
        setState((previous) => ({ ...previous, loaded: true }));
      }
    })();

    return () => { api.abortRequest(requestId); };
  }, [api, isAuthenticated]);

  useEffect(() => {
    if (!isAuth0) return;

    (async (settings: AppUserSettings) => getUser && getUser().then((u) => {
      // user has no zoordinates!
      if (!u?.[zKey]?.length && settings?.zoordinates) setState((previous) => ({ ...previous, settings: { ...(previous.settings || {} as any), zoordinates: null } }));
      // settings should always have zoordinates && they should be one of the users zoordinates!
      else if (u?.[zKey]?.length && (!settings?.zoordinates || !u[zKey].includes(settings?.zoordinates))) setState((previous) => ({
        ...previous,
        settings: {
          ...settings,
          zoordinates: u[zKey][0],
        },
      }));
    }))(state.settings);
  }, [getUser, state.settings?.zoordinates, isAuthenticated, isAuth0]);

  const extendedSettings = useMemo(() => {
    if (isBank) return bankCountryProduct;
    if (isExternalUser) return externalUserCountryProduct;

    return {
      country: translateZoordinates.zCountryToCountry(parseZoordinates(state.settings?.zoordinates)?.Country),
      product: translateZoordinates.zProductToProduct(parseZoordinates(state.settings?.zoordinates)?.Product),
    };
  }, [state.settings, isBank, isExternalUser, bankCountryProduct, externalUserCountryProduct]);

  useEffect(() => {
    if (extendedSettings.country) {
      const language = getLanguage(extendedSettings.country);
      i18n.changeLanguage(language);
      moment.locale(language);
    }
  }, [extendedSettings.country]);

  const value = useMemo(() => ({
    ...extendedSettings,
    settings: {
      ...initialSettings,
      ...state.settings,
      // eslint-disable-next-line no-nested-ternary
      theme: state.settings?.userTheme === THEMES.SYSTEM_THEME
        ? (prefersDark ? THEMES.DARK : THEMES.LIGHT)
        : (state.settings?.userTheme || THEMES.SYSTEM_THEME),
    },
    saveSettings: handleUpdate,
    setBankCountryProduct: isBank && setBankCountryProduct,
  }), [state.settings, handleUpdate, extendedSettings, prefersDark, isBank, setBankCountryProduct, parseZoordinates]);

  return (
    <SettingsContext.Provider
      value={value}
    >
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const SettingsConsumer = SettingsContext.Consumer;

export default SettingsContext;
