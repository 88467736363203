/* eslint-disable curly */
import { createContext, FC, MutableRefObject, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import HostOrigins from 'src/utils/iframe/hostOrigins';

interface ParentMessage {
  scrollInfo: { y: number };
}

export interface IframeUtilsContextProps {
  heightBaseElementRef: any;
  updateHeight: () => void;
  heightOffset: MutableRefObject<number>;
  parentMessage: ParentMessage;
  isIframed: boolean;
}

const IframeUtilsContext = createContext<IframeUtilsContextProps>(null);

export const IframeUtilsProvider: FC = (props) => {
  const { children } = props;

  const heightBaseElementRef = useRef(null);
  const heightOffset = useRef(0);
  const [isIframed, setIsIframed] = useState(false);

  const [parentMessage, setParentMessage] = useState<ParentMessage>({ scrollInfo: { y: 0 } });

  const updateHeight = useCallback(() => {
    if (heightBaseElementRef?.current) {
      window.parent.postMessage({ height: heightBaseElementRef.current.clientHeight + heightOffset.current }, process.env.REACT_APP_MINA_SIDOR_URL);
    }
  }, [heightBaseElementRef?.current, heightBaseElementRef?.current?.clientHeight, heightOffset]);

  const getMessageFromParentWindow = useCallback((event) => {
    if (!HostOrigins.includes(event.origin)) return;

    setIsIframed((p) => p || true);

    if (event?.data) setParentMessage(event.data);
  }, []);

  useEffect(() => {
    updateHeight();
  }, [updateHeight]);

  useEffect(() => {
    window.addEventListener('resize', updateHeight);
    window.addEventListener('message', getMessageFromParentWindow);

    return () => {
      window.removeEventListener('resize', updateHeight);
      window.removeEventListener('message', getMessageFromParentWindow);
    };
  }, []);

  const value: IframeUtilsContextProps = useMemo(() => ({
    heightBaseElementRef,
    updateHeight,
    heightOffset,
    parentMessage,
    isIframed,
  }), [heightBaseElementRef, updateHeight, heightOffset, parentMessage, isIframed]);

  return (
    <IframeUtilsContext.Provider value={value}>
      {children}
    </IframeUtilsContext.Provider>
  );
};

export const useIframeUtils = (): IframeUtilsContextProps => useContext(IframeUtilsContext);

export default IframeUtilsContext;
