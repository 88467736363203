import type { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import type { Employee } from 'src/api/zrm';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';

const UserTable: FC<{ users: Array<Employee> }> = (props) => {
  const { users } = props;
  const { t } = useTranslation();

  return (
    <Scrollbar>
      <Box sx={{ position: 'relative' }}>
        <Table component="div">
          <TableHead component="div">
            <TableRow component="div">
              <TableCell component="span">
                {t('Name')}
              </TableCell>
              <TableCell component="span">
                {t('Phone Number')}
              </TableCell>
              <TableCell component="span">
                {t('Email')}
              </TableCell>
              <TableCell component="span">
                {t('Last login')}
              </TableCell>
              <TableCell component="span">
                {t('Status')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody component="div">
            {users.map((user) => (
              <TableRow
                hover
                key={user.user_id}
                selected={false}
                component={RouterLink}
                to={{ pathname: `/internal-users/_/${user.user_id}` }}
                sx={{ textDecoration: 'none' }}
              >
                <TableCell component="span">
                  {`${user.first_name} ${user.last_name}`}
                </TableCell>
                <TableCell component="span">
                  {user.phone_number}
                </TableCell>
                <TableCell component="span">
                  {user.email}
                </TableCell>
                <TableCell component="span">
                  {user.last_login ? moment(user.last_login).calendar() : '-'}
                </TableCell>
                <TableCell component="span">
                  <Label
                    color={!user.deactivated ? 'success' : 'error'}
                  >
                    <Typography fontSize="0.7rem">
                      {t(!user.deactivated ? 'Active ' : 'Deactivated')}
                    </Typography>
                  </Label>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
    </Scrollbar>
  );
};

export default UserTable;
